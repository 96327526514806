import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { json } from 'body-parser';
import { Subscription, findIndex, interval } from 'rxjs';
import { timer } from 'rxjs/internal/observable/timer';
import { APPCONSTANTS, COVERAGE_MESSAGE_TYPE, EPA_STATUS, EPA_TRANSACTIon_STATUS, MANAGE_PRESCRIBER_STATUS,PRESCRIBER_QUALIFIER, PROVIDERDETAIL_STATUS, USER_ROLE } from 'src/app/constants/appconstants';
import { DrugSearchItem, SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { EpaRunnerInitiationEvent, PAInitiationWebRequest } from 'src/app/modals/epa/priorauthrequest';
import { EpaInitiationAckWebResponse, EpaInitiationResponse, PARefIDWebResponse, PaReferenceID, ResubmitSavedPA } from 'src/app/modals/epa/priorauthresponse';
import { QuestionsetViewmodal } from 'src/app/modals/epa/questionsetviewmodal';
import { Prescriber } from 'src/app/modals/login/loginwebresponse';
import { PrescriberDetailWebRequest, VerifyProviderRequest } from 'src/app/modals/prescriberSearch/prescriberSearchweberequest';
import { PrescriberAddress, PrescriberAlternateAddress, PrescriberItem, PrescriberDemographs,PrescriberDetailWebResponse, PrescriberInfoAll, VerifyProviderResponse } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { ServertimeToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { CheckCoveragePageService } from 'src/app/services/drugsearch/check-coverage-page.service';
import { DrugsearchService } from 'src/app/services/drugsearch/drugsearch.service';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { VerifyProviderService } from 'src/app/services/verifyProvider/verify-provider.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-provider',
  templateUrl: './verify-provider.component.html',
  styleUrls: ['./verify-provider.component.css']
})
export class VerifyProviderComponent implements OnInit,OnDestroy{
  
  blnShow:boolean = false;
  blnSave:boolean = false;
  blnLoader!:boolean;
  blnepaquestionPage:boolean = false;
  phoneFocus:boolean=false;
  faxFocus:boolean=false;
  providerLoader:boolean=false;
  @Input()
  prescriberDemographs!:PrescriberDemographs;
  prescriber!:PrescriberItem;
  providers:PrescriberInfoAll[]=[];
  prescribers:PrescriberInfoAll[]=[];
  presciberItems:PrescriberItem[]=[];
  selectedAddressPhone!:string;
  selectedAddressFax!:string;
  blnEditingPanelSubmitted:boolean=false;
  zeroRegExp:any=/[^0]+/i;
  alternativeAddresses!: PrescriberAlternateAddress[];
  primaryAddress!:number; 

   @Input() isProviderPage:any;
   presLabel:boolean =true;

  @Input() blnInitiatePA!:boolean;
  @Input() pageType!:string;

  selectedMedicineObject!:SelectedMedicine;
  memberDetails:any;
  drugDetails!: SelectedMedicine;
  newDate!:string;
  diagnosisDetails: any;
  quantityDetails: any;
  PAResponse:any ;
  PaRefID:any
  paReferenceId:any;
  

  blnShowHide:boolean=true;
  blnMemberEdit:boolean = false;
  blnDrugEdit:boolean = false;
  blnepaPage:boolean = false;
  claimresponse!:any;
  private subscription!:any;
  loaderText:string="";
  loaderClass:string="bigloader nopadding";
  counter = 1;
  trailAdjuducicationError = "";
  selectedMedicine!: SelectedMedicine;
  errorPopupText: string = COVERAGE_MESSAGE_TYPE.COVERAGE_ERROR;

  @ViewChild("errorModalTemplate")
  errorModalTemplateRef!:ElementRef<HTMLElement>;
  
  @ViewChild("paInitiationTemplate")
   private paInitiationTemplateRef!:TemplateRef<HTMLElement>;

   @ViewChild("paInitiationErrorTemplate")
   private paInitiationErrorTemplateRef!:TemplateRef<HTMLElement>;
   
   @ViewChild("paInitiationFaxFormTemplate")
  private paInitiationFaxFormTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("claimSummaryProgress")
  claimSummaryProgress?: ElementRef<HTMLElement>;

  @ViewChild("errorPopup", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;

   epaState:EPA_STATUS = EPA_STATUS.PA_NOT_STARTED;
   epaTimerSubscription!:Subscription;
   epaHoursRemaining!:number;
   epaMinutesRemaining!:number;
   epaSecondsRemaining!:number;
   webrequest!:PARefIDWebResponse;

 
  errorMessage:string="";
  
  // private _selectedAltAddressIndex!: number;
  // public get selectedAltAddressIndex() : number {
  //   return this._selectedAltAddressIndex;
  // }
  // public set selectedAltAddressIndex(v : number) {
  //   this._selectedAltAddressIndex = v;
  //   this.onAddressChange();
  // }

  verifyProviderPanelForm: FormGroup = new FormGroup({
    editedAddressPhone: new FormControl("",[Validators.required, Validators.pattern(/^[0-9]{1,10}$/i)]),
    editedAddressFax: new FormControl("",[Validators.required, Validators.pattern(/^[0-9]{1,10}$/i)]),
    address:new FormControl("",[Validators.required]),
    presciber:new FormControl("",[Validators.required])
  }); 

  private getEditedAddressPhone():string{
    debugger;
    return this.verifyProviderPanelForm.controls['editedAddressPhone'].value;
  }
  private setEditedAddressPhone(val:string):void{
    this.verifyProviderPanelForm.controls['editedAddressPhone'].setValue(val);
  }
  private getEditedAddressFax():string{
    return this.verifyProviderPanelForm.controls['editedAddressFax'].value;
  }
  private setEditedAddressFax(val:string):void{
    this.verifyProviderPanelForm.controls['editedAddressFax'].setValue(val);
  }

  private getpresciber():string{
    return this.verifyProviderPanelForm.controls['presciber'].value;
  }

  private setpresciber(val:string):void{
    this.verifyProviderPanelForm.controls['presciber'].setValue(val);
  }

  private getAddress():string{
    debugger;
    return this.verifyProviderPanelForm.controls['address'].value;
  }

  private setAddress(val:string):void{
    debugger
    this.verifyProviderPanelForm.controls['address'].setValue(val);
  }

// //////////////////////

private _selectedAltAddressIndex!: number;
  public get selectedAltAddressIndex() : number {
    return this._selectedAltAddressIndex;
  }
  public set selectedAltAddressIndex(v : number) {
    debugger;
    this._selectedAltAddressIndex = v;
    this.onAddressChange();
  }

private _selectedprescriberIndex!: number;
  public get selectedprescriberIndex() : number {
    debugger;
    return this._selectedprescriberIndex;
  }
  public set selectedprescriberIndex(v : number) {
    debugger;
    this._selectedprescriberIndex = v;
    this.onPrescriberChange();
  }

  
  /*private _NPI !: string;
  public get NPI() : string {
    debugger;
    if(this.prescriber!=undefined){
      return this.prescriberService.findPrescriberNPI(this.prescriber.identifiers);
    }
    else
    {
        return "";
    }
  }*/
  private _NPI !: string;
  public get NPI() : string {
    debugger;
    if(this.prescriber.identifier!=undefined){
      return this.prescriberService.findPrescriberNPI(this.prescriber.identifier);      
    }
    else
    return "";
} 
  /*private _NPI !: string;
  public get NPI() : string {
    if(this.prescriber!=undefined)
      return this.prescriberService.findPrescriberNPI(this.prescriber.identifiers);
    else
    {
        return this.prescriberService.findPrescriberNPI(this.prescriberDemographs.identifiers);
    }
  }*/
  
  private _fullName !: string;
  public get fullName() : string {
    if(this.prescriber!=undefined){
      return this.prescriberService.getSelectedPrescriberFullName(this.prescriber);
    }
    else
      return "";
  }  

  
  /*private _instanceId !: string;
  public get instanceId() : string {
   if(this.prescriber!=undefined) 
    return this.prescriber.instanceId;
   else
    return "";
  }*/

//////////////////




  
  constructor(private epaProcessor:EpaprocessorService,private modalService: NgbModal, private logger: LoggerService,private router: Router,private prescriberService:PrescriberSearchService, private providerHelper:VerifyProviderService, private profileService:ProfileService,private trailclaimadjudicationHelper: CheckCoveragePageService, private drugHelper: DrugsearchService, private memberHelper: MembersearchService,private servertimeToStandardDate:ServertimeToStandardDate){
  
  }

  ngOnInit(): void {
    debugger;
    this.providerLoader=true;
    console.log(this.pageType);
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    if(sessionStorage.getItem('IntiatePA')!=undefined){
      this.blnInitiatePA = sessionStorage.getItem('IntiatePA')=="true"?true:false;
    }
    else{
      this.blnInitiatePA = false;
    }
    if(sessionStorage.getItem('editProvider')!=undefined){
      this.isProviderPage = sessionStorage.getItem('editProvider')=="true"?true:false;
    }

    this.memberDetails = this.memberHelper.getSelectedMember();
    this.newDate = this.servertimeToStandardDate.transform(this.memberDetails.dateOfBirth);
    console.log(this.newDate);
    this.drugDetails = JSON.parse(""+sessionStorage.getItem("selectedMedicine"));
    this.PAResponse = JSON.parse(""+sessionStorage.getItem('PAResponse'));
    debugger;
     this.PaRefID = sessionStorage.getItem('paReferenceID');
     this.paReferenceId = sessionStorage.getItem('paRefID');
     console.log(this.PAResponse);
   // this.drugDetails = (""+sessionStorage.getItem('drugDetails')?.toString);
    //  console.log(this.PAResponse.paRefID);
    // this.diagnosisDetails = JSON.parse(""+sessionStorage.getItem('diagnosisDetails'));
    // this.quantityDetails = JSON.parse(""+sessionStorage.getItem('quantityCalculated'));
    // console.log(this.drugDetails,this.diagnosisDetails,this.quantityDetails);
    this.blnMemberEdit = false;
    this.blnDrugEdit = false;
    if(this.profileService.USER_ROLE==USER_ROLE.ADMIN){
      let webrequest:any = new VerifyProviderRequest();
      webrequest.adminPresbrID = ""+this.profileService.loginuser?.prescriber?.presbrId;
      webrequest.uuid = ""+this.profileService.loginuser?.prescriber?.uuid;
      this.providerHelper.getRecentPresbrDtls(webrequest).then((response:VerifyProviderResponse)=>{
        this.providerLoader = true;
        console.log(response);
        this.providers = response.prescriberInfoAll;
        const presbrdetailswebrequest:PrescriberDetailWebRequest = new PrescriberDetailWebRequest();

        for(let i=0;i<this.providers.length;i++){
          if(this.providers[i].npi!=""+this.profileService.loginuser?.prescriber?.npi){
           // presbrdetailswebrequest.instanceId = ""+this.providers[i].instanceId;
            presbrdetailswebrequest.prescriberId = ""+this.providers[i].npi;
            //presbrdetailswebrequest.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;
            this.alternativeAddresses=[];
            this.providerHelper.getPresbrDtls(presbrdetailswebrequest).then((response:PrescriberDetailWebResponse)=>{
              this.blnLoader=false;
              this.providerLoader = false;
              // this.drugDetails.
              debugger;
              if(response.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE){
                let index=this.presciberItems.push(response.prescriberItem);
                // this.prescriber=response.response.prescriberDetailItem;
                // this.alternativeAddresses=this.providerHelper.getPrescriberValidAlternativeAddresses(this.presciberItems[index].alternateAddresses);
                // for(let i=0;i<this.alternativeAddresses.length;i++){
                //   if(this.alternativeAddresses[i].qualifier="PRIMARY")
                //     this.selectedAltAddressIndex=i;
                // }
              } else {
                // this.prescriberService.clearSelectedPrescriberDetail();
                // if(response.status.respCode == APPCONSTANTS.API_RESPONSE_NO_RECORDS)
                //   this.detailStatus=PRESCRIBERDETAIL_STATUS.NOT_FOUND;
                // else
                //   this.detailStatus=PRESCRIBERDETAIL_STATUS.SERVER_ERROR;
              }
            },(error:any)=>{
            //   this.blnLoader=false;
            //   this.detailStatus=PRESCRIBERDETAIL_STATUS.SERVER_ERROR;
            // }).catch((error:any)=>{
            //   this.blnLoader=false;
            //   this.detailStatus=PRESCRIBERDETAIL_STATUS.SERVER_ERROR;
            }); 
          }
           
        }
        // End of for loop for getting all the prescribers

      debugger;
        let indexofDefaultPrescriber = this.providers.findIndex(item=>item.npi==this.profileService.loginuser?.prescriber?.npi);
        /*presbrdetailswebrequest.instanceId = indexofDefaultPrescriber==-1?""+this.profileService.loginuser?.prescriber?.instanceId:this.providers[indexofDefaultPrescriber].instanceId;
        // what if both are empty that we need to see, if both are empty then we need to set the default value to A6-IRX
        if(presbrdetailswebrequest.instanceId=="" && this.providers.length>0){
          presbrdetailswebrequest.instanceId=""+this.providers[0].instanceId;
        }
        if(presbrdetailswebrequest.instanceId==""){
          presbrdetailswebrequest.instanceId="A6-IRX";
        }
        console.log(presbrdetailswebrequest.instanceId+""+indexofDefaultPrescriber);*/
        presbrdetailswebrequest.prescriberId = ""+this.profileService.loginuser?.prescriber?.npi;
        //presbrdetailswebrequest.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;
        this.alternativeAddresses=[];
        this.providerHelper.getPresbrDtls(presbrdetailswebrequest).then((response:PrescriberDetailWebResponse)=>{
          debugger;
          this.blnLoader=false;
          let SelectedMedicineObject : SelectedMedicine = new SelectedMedicine();
             // this.claimresponse = this.SelectedMedicineObject;
              SelectedMedicineObject = this.drugDetails;
              SelectedMedicineObject.pharmacySearchItem=JSON.parse(""+sessionStorage.getItem("selectedpharmacy"));
              SelectedMedicineObject.selectedPrescriberDetail = response.prescriberItem;
              SelectedMedicineObject.selectedPrescriberDetail.selectedAlternativeAddress = 0;
              //SelectedMedicineObject.selectedPrescriberDetail.instanceId=presbrdetailswebrequest.instanceId;

              this.selectedMedicineObject = SelectedMedicineObject;
              this.claimresponse = this.selectedMedicineObject;
              console.log(this.claimresponse);
          if(response.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE){
            let index=this.presciberItems.push(response.prescriberItem);
            // this.prescriber=response.response.prescriberDetailItem;
            debugger;
            this.selectedprescriberIndex=index-1;
            this.alternativeAddresses=[];
            this.alternativeAddresses=this.providerHelper.getPrescriberValidAlternativeAddresses(this.presciberItems[index-1].alternateAddresses);
            for(let i=0;i<this.alternativeAddresses.length;i++){
              //this.alternativeAddresses=[];
              if(this.alternativeAddresses[i].qualifier?.toLowerCase() === MANAGE_PRESCRIBER_STATUS.PRIMARY_ADDRESS){
                //this.selectedAltAddressIndex=i;
                this.selectedAltAddressIndex=i;
                break;
              }
              this.selectedAddressFax=this.alternativeAddresses[i].contact.fax;
              this.selectedAddressPhone=this.alternativeAddresses[i].contact.phone1;
                this.updatePrescriberDetailIntoSession();
            }
            console.log("AlternativeAddresses");
            console.log(this.alternativeAddresses);
            this.providerLoader = false;
          } else {
            // this.prescriberService.clearSelectedPrescriberDetail();
            // if(response.status.respCode == APPCONSTANTS.API_RESPONSE_NO_RECORDS)
            //   this.detailStatus=PRESCRIBERDETAIL_STATUS.NOT_FOUND;
            // else
            //   this.detailStatus=PRESCRIBERDETAIL_STATUS.SERVER_ERROR;
          }
        },(error:any)=>{
        //   this.blnLoader=false;
        //   this.detailStatus=PRESCRIBERDETAIL_STATUS.SERVER_ERROR;
        // }).catch((error:any)=>{
        //   this.blnLoader=false;
        //   this.detailStatus=PRESCRIBERDETAIL_STATUS.SERVER_ERROR;
        });


        });
    }else{
      const presbrdetailswebrequest:PrescriberDetailWebRequest = new PrescriberDetailWebRequest();
      debugger;
        let indexofDefaultPrescriber = this.providers.findIndex(item=>item.npi==this.profileService.loginuser?.prescriber?.npi);
        /*presbrdetailswebrequest.instanceId = indexofDefaultPrescriber==-1?""+this.profileService.loginuser?.prescriber?.instanceId:this.providers[indexofDefaultPrescriber].instanceId;
        // what if both are empty that we need to see, if both are empty then we need to set the default value to A6-IRX
        if(presbrdetailswebrequest.instanceId=="" && this.providers.length>0){
          presbrdetailswebrequest.instanceId=""+this.providers[0].instanceId;
        }
        if(presbrdetailswebrequest.instanceId==""){
          presbrdetailswebrequest.instanceId="A6-IRX";
        }
        console.log(presbrdetailswebrequest.instanceId+""+indexofDefaultPrescriber);*/
        presbrdetailswebrequest.prescriberId = ""+this.profileService.loginuser?.prescriber?.npi;
        //presbrdetailswebrequest.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;
        this.alternativeAddresses=[];
        this.providerHelper.getPresbrDtls(presbrdetailswebrequest).then((response:PrescriberDetailWebResponse)=>{
          debugger;
          this.blnLoader=false;
          let SelectedMedicineObject : SelectedMedicine = new SelectedMedicine();
             // this.claimresponse = this.SelectedMedicineObject;
              SelectedMedicineObject = this.drugDetails;
              SelectedMedicineObject.pharmacySearchItem=JSON.parse(""+sessionStorage.getItem("selectedpharmacy"));
              SelectedMedicineObject.selectedPrescriberDetail = response.prescriberItem;
              SelectedMedicineObject.selectedPrescriberDetail.selectedAlternativeAddress = 0;
              //SelectedMedicineObject.selectedPrescriberDetail.instanceId=presbrdetailswebrequest.instanceId;

              this.selectedMedicineObject = SelectedMedicineObject;
              this.claimresponse = this.selectedMedicineObject;
              console.log(this.claimresponse);
          if(response.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE){
            let index=this.presciberItems.push(response.prescriberItem);
            // this.prescriber=response.response.prescriberDetailItem;
            debugger;
            this.selectedprescriberIndex=index-1;
            this.alternativeAddresses=[];
            this.alternativeAddresses=this.providerHelper.getPrescriberValidAlternativeAddresses(this.presciberItems[index-1].alternateAddresses);
            for(let i=0;i<this.alternativeAddresses.length;i++){
              if(this.alternativeAddresses[i].qualifier?.toLowerCase() === MANAGE_PRESCRIBER_STATUS.PRIMARY_ADDRESS)
                {
              this.selectedAltAddressIndex=i;
              break;
                }
              this.selectedAddressFax=this.alternativeAddresses[i].contact.fax;
              this.selectedAddressPhone=this.alternativeAddresses[i].contact.phone1;
                this.updatePrescriberDetailIntoSession();
            }
            console.log("AlternativeAddresses");
            console.log(this.alternativeAddresses);
            this.providerLoader = false;
          } else {
            // this.prescriberService.clearSelectedPrescriberDetail();
            // if(response.status.respCode == APPCONSTANTS.API_RESPONSE_NO_RECORDS)
            //   this.detailStatus=PRESCRIBERDETAIL_STATUS.NOT_FOUND;
            // else
            //   this.detailStatus=PRESCRIBERDETAIL_STATUS.SERVER_ERROR;
          }
        },(error:any)=>{
        //   this.blnLoader=false;
        //   this.detailStatus=PRESCRIBERDETAIL_STATUS.SERVER_ERROR;
        // }).catch((error:any)=>{
        //   this.blnLoader=false;
        //   this.detailStatus=PRESCRIBERDETAIL_STATUS.SERVER_ERROR;
        });
    }
      


        // this.providerLoader = false;
        if(this.blnInitiatePA){
          this.epaProcessor.epaSubscription.subscribe
          this.subscription = this.epaProcessor.epaSubscription.subscribe((value:EpaRunnerInitiationEvent)=>{
          debugger;
          if(value.status == EPA_STATUS.PA_INITIATION 
            && value.claimresponse!=undefined && value.paRefId!=undefined
            && this.paInitiationTemplateRef!=undefined){
            this.epaState = value.status;
            }
          });
        }
  }

  discardEdit():void{
    this.logger.userAction("Discarded Editing the Provider.");
    this.blnShow = false;
  }

  edit(){
    this.blnEditingPanelSubmitted=false;
     this.blnShow = true;
     this.logger.userAction("Editing the Provider.");
     this.setEditedAddressFax(this.selectedAddressFax);
     this.setEditedAddressPhone(this.selectedAddressPhone);
   }

   saveEdit(){
    debugger;
    this.blnEditingPanelSubmitted=true;
    this.selectedAddressPhone = this.getEditedAddressPhone();
      this.selectedAddressFax = this.getEditedAddressFax();
      this.alternativeAddresses[this.selectedAltAddressIndex].contact.fax=this.selectedAddressFax;
      this.alternativeAddresses[this.selectedAltAddressIndex].contact.phone1=this.selectedAddressPhone;
      this.updatePrescriberDetailIntoSession();
      this.logger.userAction("Saving the Provider.");
      this.blnShow = false;
  }
   /* onAddressChange():void{
      debugger;
    if(Array.isArray(this.alternativeAddresses) && this.alternativeAddresses.length>0){
      this.selectedAddressFax = this.alternativeAddresses[this.selectedAltAddressIndex].contact.fax;
      this.selectedAddressPhone = this.alternativeAddresses[this.selectedAltAddressIndex].contact.phone1;
    } else {
      this.selectedAddressPhone="0";
      this.selectedAddressFax="0";
    }
    this.updatePrescriberDetailIntoSession();
    // if(this.detailStatus == PRESCRIBERDETAIL_STATUS.EDIT){
    //   this.edit();
    // } else {
    //   this.updatePrescriberDetailIntoSession();
    // }
  }*/
  onAddressChange(): void {
    if (Array.isArray(this.alternativeAddresses) && this.alternativeAddresses.length > 0 && this.selectedAltAddressIndex !== undefined && this.alternativeAddresses[this.selectedAltAddressIndex]) {
      const selectedAddress = this.alternativeAddresses[this.selectedAltAddressIndex];
      if (selectedAddress.contact) {
        this.selectedAddressFax = selectedAddress.contact.fax || "0";
        this.selectedAddressPhone = selectedAddress.contact.phone1 || "0";
      } else {
        this.selectedAddressPhone = "0";
        this.selectedAddressFax = "0";
      }
    } else {
      console.error('Selected address is undefined');
    }
    this.updatePrescriberDetailIntoSession();
  }
  private updatePrescriberDetailIntoSession():void{
    debugger;
    if(this.prescriber!=undefined && Array.isArray(this.prescriber.alternateAddresses) 
      && this.prescriber.alternateAddresses.length>0 
      && this.prescriber.alternateAddresses[this.selectedAltAddressIndex]!=undefined){
        const updatedPrescriberDetail:PrescriberItem = JSON.parse(JSON.stringify(this.prescriber));
        updatedPrescriberDetail.contact.phone1=this.selectedAddressPhone;
        updatedPrescriberDetail.contact.fax=this.selectedAddressFax;
        updatedPrescriberDetail.selectedAlternativeAddress = this.selectedAltAddressIndex;
        this.prescriberService.storeSelectedPrescriberDetail(updatedPrescriberDetail);
        this.selectedMedicineObject.selectedPrescriberDetail=updatedPrescriberDetail;
      }
  }

  
  onPrescriberChange() {
    this.prescriber = this.presciberItems[this.selectedprescriberIndex];
    this.alternativeAddresses = this.providerHelper.getPrescriberValidAlternativeAddresses(
      this.presciberItems[this.selectedprescriberIndex].alternateAddresses
    );
    console.log(this.alternativeAddresses);
    for (let i = 0; i < this.alternativeAddresses.length; i++) {
    
      if (this.alternativeAddresses[i].qualifier?.toLowerCase() === MANAGE_PRESCRIBER_STATUS.PRIMARY_ADDRESS) {
        this.selectedAltAddressIndex = i;
        //this.selectedAltAddressIndex = this.alternativeAddresses, (item) => item.qualifier?.toLowerCase() === MANAGE_PRESCRIBER_STATUS.PRIMARY_ADDRESS.toLowerCase());
        break; // Exit the loop once the primary address is found
      }
    }
  }

  getAddressLine3(prescriberAdd:PrescriberAlternateAddress):string{
    return this.prescriberService.getAlternativeAddressLine3(prescriberAdd);
  }

  show(){
    this.blnShowHide = false;
  }
  hide(){
    this.blnShowHide = true;
  }
  goto(routeUrl:string){
    //  this.blnMemberEdit = ""+sessionStorage.getItem("blnMemberEdit");
    this.router.navigateByUrl("/"+routeUrl);
  }
  back(event: any): void {
    this.logger.userAction("Back clicked.");
    event.preventDefault();
    this.router.navigateByUrl("/PriorAuthChecker/DrugSearch");
}
backEpa(event: any): void {
  this.logger.userAction("Back clicked.");
  event.preventDefault();
  if(sessionStorage.getItem("epaResubmit")!=undefined){
    this.router.navigateByUrl("/home");
  }else if(sessionStorage.getItem("epaMemberResubmit")!=undefined){
    this.router.navigateByUrl("/searchMedicationClaims/MemberDetail");
  }else{
    this.router.navigateByUrl("/ePA/DrugSearch");
  }
}

  changeMember(){
    this.blnMemberEdit = true;
    sessionStorage.setItem("blnMemberEdit",""+this.blnMemberEdit);
    this.goto('/ePA/MemberSearch');
  }
  changeDrug(){
    this.blnDrugEdit = true;
    sessionStorage.setItem("blnDrugEdit",""+this.blnDrugEdit);
    this.goto('/ePA/DrugSearch');
  }
  initiatePA():void{ 
    debugger;
    const webrequest:PAInitiationWebRequest= this.epaProcessor.extractPAInitiationRequestFromSelectMadicine(this.selectedMedicineObject);
    debugger;
    webrequest.pAReferenceID = this.PaRefID;
    this.modalService.open(this.paInitiationTemplateRef,{size:'md',backdrop:'static'});
    this.epaProcessor.submitPAInitiationRequest(webrequest).then((response:EpaInitiationAckWebResponse)=>{
     debugger;
    //console.log("firstcall"+response);
    this.epaState = EPA_STATUS.PA_INITIATION_SUCCESS;
    //Below timeout is for initial delay before start pooling the DB for Questionset availability.
    setTimeout(()=>{
      console.log(this.PAResponse);
      this.startPoolingQuestionSet(this.PAResponse);
    },1000);
  },(error:any)=>this.handleAPIError(error)).catch((error:any)=>this.handleAPIException(error));
    }
    
    private startPoolingQuestionSet(paRef:PARefIDWebResponse):void{
      debugger;
      this.epaState = EPA_STATUS.PA_QUESTIONNARIE_CHECK;
      this.epaProcessor.poolPAInitiationResponse(""+paRef.paRefID).then((response:EpaInitiationResponse)=>{
        this.epaState = EPA_STATUS.PA_QUESTIONNARIE_RETRIVED;
        const epaQuestions: QuestionsetViewmodal = this.epaProcessor.extactQuestionSetModalFromSavedPA(response.paInitiationResponseWeb.body);
        epaQuestions.paReferenceID.completePaRefId = paRef.paRefPrefix+paRef.paRefID;
        this.epaProcessor.storeQuestionSetViewModal(epaQuestions);
        const $timer:any = timer(0,1000);
        this.epaTimerSubscription = $timer.subscribe((value:any)=>{
          this.showEpaTimeline(epaQuestions.deadlineForReply);
        });
      },(error:any)=>this.handleAPIError(error)).catch((error:any)=>this.handleAPIException(error));
    }
  
    private showEpaTimeline(deadlineForReply:number):void{
      this.epaState = EPA_STATUS.PA_QUESTIONNARIE_TIMELINE;
      const deadLine: Date = new Date(deadlineForReply);
      //Removed 1 minute grace period to submit the questionset.
      deadLine.setMinutes(deadLine.getMinutes()-1);
      const today: Date = new Date();
      
      if(deadLine instanceof Date && !isNaN(deadLine.valueOf())){
        //valid date.
        let differenceinmilliseconds:number= deadLine.getTime() - today.getTime();
        
        this.epaHoursRemaining = parseInt((differenceinmilliseconds/(1000*60*60)).toString());
        differenceinmilliseconds = differenceinmilliseconds - (this.epaHoursRemaining * 60 * 60 * 1000);
  
        this.epaMinutesRemaining = parseInt((differenceinmilliseconds/(1000*60)).toString());
        differenceinmilliseconds = differenceinmilliseconds - (this.epaMinutesRemaining * 60 * 1000);
  
        this.epaSecondsRemaining = parseInt((differenceinmilliseconds/1000).toString());
      }
  
    }

    private handleAPIException(error:any):void{
      this.epaState = EPA_STATUS.PA_NOT_STARTED;
      if(typeof error == 'object' &&  (error.status==-1 || error.status==0)){
        //errorhtmlmodal:
        this.errorMessage="The system cannot process your request at this time.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
        this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md',backdrop:'static'});
      } else {
        //errorhtmlmodal:
        this.errorMessage="An error occurred. Please call customer service at<br/>1-866-842-3278.";
        this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md',backdrop:'static'});
      }
    }

    private handleAPIError(error:any):void{
      debugger;
      this.epaState = EPA_STATUS.PA_NOT_STARTED;
      this.modalService.dismissAll();
      if(typeof error == 'object'){
        if(typeof error=="object" && (error.status==-1 || error.status==0)){
          //errorhtmlmodal:
          this.errorMessage="The system cannot process your request at this time.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
          this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md',backdrop:'static'});
        } else {
          const message:string=(error.status.respMessage!=undefined && error.status.respMessage.length>0)?error.status.respMessage:error.status.statusDesc;
          if(typeof error.status =='object' && error.status.statusCode!=undefined 
          && (error.status.statusCode?.toLowerCase()=='bx' || error.status.statusCode?.toLowerCase()=='co')){
            //connectionerrormodal:faxMsg
            this.errorMessage=message;
            this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md',backdrop:'static'});
          } else {
            if(message.indexOf(EPA_TRANSACTIon_STATUS.PlanNotSupported)!=-1 && message.indexOf(EPA_TRANSACTIon_STATUS.ContactRx)!=-1){
              //connectionerrormodal: 
              this.errorMessage="Electronic prior authorization cannot be initiated for this <br/> member because the member's plan does not support it.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
              this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md',backdrop:'static'});
            } else {
              //errormodal:message
              this.errorMessage=message;
              this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md',backdrop:'static'});
            }
          }
        }
      } else {
        //errormodal:message
        if(error == EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR){
          //errorhtmlmodal:
          this.errorMessage="An error occurred. Please call customer service at<br/>1-866-842-3278.";
          this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md',backdrop:'static'});
        } else {
          if(error == EPA_TRANSACTIon_STATUS.POOLING_TIME_EXCEEDED){
            this.errorMessage="The system cannot process your request at this time.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
            this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md',backdrop:'static'});
          } else {
            //errormodal: show error
            this.errorMessage=error;
            this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md',backdrop:'static'});
          }
        }
      }
    }
    goToEPAQuestions():void{
      debugger;
      if(this.epaState == EPA_STATUS.PA_QUESTIONNARIE_TIMELINE){
        this.logger.userAction("View EPA Question Clicked");
        this.modalService.dismissAll();
        this.router.navigateByUrl("/ePA/ePAquestions");
        this.blnepaquestionPage = true;
          sessionStorage.setItem('questionspage',""+this.blnepaquestionPage);
        }
    }
  
    openfaxforms():void{
      this.logger.userAction("View Fax Forms clicked.");
      window.open(environment.faxForm, "_blank");
    }
  
    goToHome():void{
      this.logger.userAction("Routing to Home Page");
      this.router.navigateByUrl("/home");
    }
    ngOnDestroy():void{
      // debugger;
      // if(this.epaTimerSubscription!=undefined){
      //   this.epaTimerSubscription.unsubscribe();
      // }
      // this.epaProcessor.cancelAllSubscriptions();
      // this.subscription.unsubscribe();
    }

    checkCoverage() {
      this.logger.userAction("Check Coverage");
      debugger;
      sessionStorage.setItem("selectedMedicine",JSON.stringify(this.selectedMedicineObject));
      this.modalService.open(this.claimSummaryProgress, { modalDialogClass: 'claimSummaryProgress' ,windowClass:'loadingContent1',backdrop: 'static'});
      this.trailclaimadjudicationHelper.checkCoverage(this.selectedMedicineObject).then((data: SelectedMedicine) => {
        debugger;
        if (data) {
          this.counter = 2;
          // this.selectedMedicine.forEach((item: SelectedMedicine) => {
            // this.selectedMedicine.memberDetailResponse = this.memberResponse;
          // });
          // sessionStorage.setItem("selectedMedicineList", JSON.stringify(this.selectedMedicineObject));

          // this.modalService.dismissAll();
          // this.router.navigateByUrl("/summary");
          // Adding delay of 2 sec before moving to summary page
          if (data.trailadjudicationwebresponse.status.respCode != APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
            this.modalService.dismissAll();
            this.errorPopupText = ""+data.trailadjudicationwebresponse.status.responseDescriptionCode;
            this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs', backdrop: 'static' });
          }else{
          const source = interval(2000);
          this.subscription = source.subscribe(() => {
            this.counter++;
            //console.log(this.counter);
            if (this.counter == 4) {
              //console.log(this.counter);
              this.subscription.unsubscribe();
              this.modalService.dismissAll();
              console.log("Moving to Summary:");
              console.log(this.selectedMedicineObject);
              this.selectedMedicineObject=data;
              sessionStorage.setItem("selectedMedicine",JSON.stringify(this.selectedMedicineObject));
                this.router.navigateByUrl("/PriorAuthChecker/summary");
            }
          });
        }
        }
      }, (error: any) => {

      }).catch((error: any) => {

      });


      }

      dismissModal() {
        this.modalService.dismissAll();
      }
       checkednpi!:String;
      changePrescriber($event:String){
        this.checkednpi = $event;
        console.log(this.checkednpi);

      }
}
