import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SteponeNPISearchRequest } from 'src/app/modals/registration/SteponeNPISearchRequest'
import { Address, SteponePharmacyRequesthemi } from 'src/app/modals/registration/SteponePharmacyRequest'
import { SteponeNPISearchResponse, PrescriberSearchItem } from 'src/app/modals/registration/SteponeNPISearchResponse'
import { TableHeaderCellData, TableRowData, TableCellData, TableClickEvent, DataTableType } from 'src/app/modals/datatable/datatable';
import { APPCONSTANTS, BOOLEAN_FLAG, PRESCRIBER_QUALIFIER, USER_ROLE } from 'src/app/constants/appconstants';
import { environment } from 'src/environments/environment';
import { SteponePharmacyResponsehemi, Pharmacy } from 'src/app/modals/registration/SteponePharmacyResponse'
import { SteponeInitiationRequest } from 'src/app/modals/registration/SteponeInitiationRequest';
import { SteponeInitiationResponse } from 'src/app/modals/registration/SteponeInitiationResponse';
import { SteponeLinkProvisionRequest } from 'src/app/modals/registration/SteponeLinkProvisionRequest';
import { SteponePrescriberDetailsRequest } from 'src/app/modals/registration/SteponePrescriberDetailsRequest';
import { SteponeLinkProvisionResponse } from 'src/app/modals/registration/SteponeLinkProvisionResponse';
import { SteponePrescriberDetailsResponse } from 'src/app/modals/registration/SteponePrescriberDetailsResponse';
import { SteponePharmacyDetailsRequest } from 'src/app/modals/registration/SteponePharmacyDetailsRequest';
import { SteponeRegisterOptumIdStatusRequest } from 'src/app/modals/registration/SteponeRegisterOptumIdStatusRequest';
import { SteponePharmacyDetailsResponse } from 'src/app/modals/registration/SteponePharmacyDetailsResponse';
import { Renderer2, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationhelperService } from 'src/app/services/registrationhelper/registrationhelper.service';
import { registrationService } from 'src/app/services/registrationSteps/registration.service';
import { PlanSearch } from 'src/app/modals/drugSearch/pharmaySearchrequest';
import { ProfileService } from 'src/app/services/profile/profile.service';



@Component({
  selector: 'app-registerstepone',
  templateUrl: './registerstepone.component.html',
  styleUrls: ['./registerstepone.component.css']
})
export class RegistersteponeComponent implements OnInit {
  @ViewChild('vanish') vanish!: ElementRef;
  @ViewChild('vanish1') vanish1!: ElementRef;



  private firstHeader: TableHeaderCellData = new TableHeaderCellData();
  private secondHeader: TableHeaderCellData = new TableHeaderCellData();
  private thirdHeader: TableHeaderCellData = new TableHeaderCellData();
  private fourthHeader: TableHeaderCellData = new TableHeaderCellData();
  private fifthHeader: TableHeaderCellData = new TableHeaderCellData();



  PrescribersHeaders!: TableHeaderCellData[];

  prescriberSearchHistory !: TableRowData<PrescriberSearchItem>[];

  prescriberList: PrescriberSearchItem[] = [];

  PharmacyHeaders !: TableHeaderCellData[];

  pharmacySearchHistory!: TableRowData<Pharmacy>[];

  pharmacyList: Pharmacy[] = [];

  currentPageNumber: number = 1;
  recordsPerPage: number = environment.paginationRecordsPerPage;
  totalEntries: number = 0;
  totalPagesCount: number = -1;

  pharmacyDistance: any;
  prescriberState: any;


  savedPrescriberUserRole: string | null = '';
  savedPharmacyUserRole: string | null = '';

  savedPrescriberId: string | null = '';

  savedPrescriberLastName: string | null = '';
  savedPrescriberFirstName: string | null = '';
  savedPrescriberCity: string | null = '';
  savedPrescriberState: string | null = '';

  savedPharmacyName: string | null = '';
  savedPharmacyZipCode: string | null = '';
  savedPharmacyDistance: string | null = '';

  MailOrderNPI1: string = '1669498515';
  MailOrderNPI2: string = '1497704431';

  datatableType: DataTableType = DataTableType.HIGHLIGHT_ROWS;

  submittedNPIForm: boolean = false;
  submittedNPINameForm: boolean = false;
  submittedPharmacyForm: boolean = false;

  recentprescriberSearches: PrescriberSearchItem[] = new Array();
  recentpharmacySearches: Pharmacy[] = new Array();
  filterlist: any;
  userRole: String = "";

  cancelRegCheck: any;
  displayCancelPopup: boolean = false;

  roleNotSelected: boolean = false;

  selectedTab: any;

  linReqId: string = '';

  secondClickPrevent: boolean = false;

  constructor(private registrationHelper: RegistrationhelperService, private fb: FormBuilder, private registrationService: registrationService, private renderer: Renderer2, private router: Router, private profileservice: ProfileService) {
    this.firstHeader.content = "Name";
    this.firstHeader.sortable = true;
    this.firstHeader.sortAscending = true;
    this.firstHeader.selectedForSort = true;
    this.secondHeader.content = "Primary Address";
    this.thirdHeader.content = "NPI";
    this.fourthHeader.content = "Distance";
    this.fifthHeader.content = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
    this.fifthHeader.cssClass = "tablesmallheader25";

    this.PrescribersHeaders = [];
    this.PrescribersHeaders.push(this.firstHeader);
    this.PrescribersHeaders.push(this.secondHeader);
    this.PrescribersHeaders.push(this.thirdHeader);

    this.PharmacyHeaders = [];
    this.PharmacyHeaders.push(this.firstHeader);
    this.PharmacyHeaders.push(this.secondHeader);
    this.PharmacyHeaders.push(this.fourthHeader);


  }
  //cancel registration sucess popup vanish code
  ngAfterViewInit() {
    setTimeout(() => {
      this.renderer.removeChild(this.vanish.nativeElement.parentNode, this.vanish.nativeElement);
    }, 3000);
  }

  userDeactivated: boolean = false;
  deactRole!: string;
  deactNpi!: string;
  loginUserData!: any;
  deactPharmacyDetails!: any;


  ngOnInit(): void {
    debugger;

    var temp = sessionStorage.getItem('loginuserdata');
    if (temp != undefined || temp != null) {

      this.loginUserData = JSON.parse(temp);
    }

    let deactPharmacy = sessionStorage.getItem('deactPharmaDetails');
    if (deactPharmacy != null && deactPharmacy != undefined) {
      this.deactPharmacyDetails = JSON.parse(deactPharmacy);
    }

    //code to check reActivatedInd is not undefined and doesnt throw error
    // if (this.loginUserData != null && this.loginUserData.reActivateInd != undefined && this.loginUserData.reActivateInd == BOOLEAN_FLAG.YES) {
    //}
    if (this.loginUserData != null && this.loginUserData.reActivateInd != undefined && this.loginUserData.reActivateInd == BOOLEAN_FLAG.YES) {
      this.userDeactivated = true;
      this.deactNpi = this.loginUserData.prescribers[0].npi;
      this.deactRole = this.loginUserData.prescribers[0].roleTypeId;
      console.log(this.deactNpi, this.deactRole);

      if (this.deactRole == USER_ROLE.PRESCRIBER.toString()) {
        this.Prescriber_Role.controls['prescriberRoleValue'].setValue('1');
        this.filterStatus = 1;
        this.sh = '1';
        this.selectedTab = 'prescriberNPI';
        this.Prescriber_NPI_Form.controls['Prescriber_NPI_Field'].setValue(this.deactNpi);
        this.onSubmitPrescriberNPI(this.Prescriber_NPI_Form.value);
      }
      else if (this.deactRole == USER_ROLE.ADMIN.toString()) {
        this.Prescriber_Role.controls['prescriberRoleValue'].setValue('2');
        this.filterStatus = 2;
        this.sh = '1';
        this.selectedTab = 'prescriberNPI';
        this.Prescriber_NPI_Form.controls['Prescriber_NPI_Field'].setValue(this.deactNpi);
        this.onSubmitPrescriberNPI(this.Prescriber_NPI_Form.value);
      }

      else if (this.deactRole == USER_ROLE.PHARMACIST.toString()) {
        this.Pharmacy_Role.controls['pharmacyRoleValue'].setValue('1');
        this.sh = '0';
        this.filterStatus = 3;
        if (this.deactPharmacyDetails != null && this.deactPharmacyDetails != undefined) {
          this.Pharmacy_Form.controls['Pharmacy_Name'].setValue(this.deactPharmacyDetails.pharmacyDetailItems[0].pharmacyName);
          this.Pharmacy_Form.controls['Zip_Code'].setValue(this.deactPharmacyDetails.pharmacyDetailItems[0].storeAddress.zip1);
          this.Pharmacy_Form.controls['Distance'].setValue('25');
          this.onSubmitPharmacy(this.Pharmacy_Form.value);
        }
      }

      else if (this.deactRole == USER_ROLE.SPECIALTYPHARMACIST.toString()) {
        this.Pharmacy_Role.controls['pharmacyRoleValue'].setValue('2');
        this.sh = '0';
        this.filterStatus = 4;
        if (this.deactPharmacyDetails != null && this.deactPharmacyDetails != undefined) {
          this.Pharmacy_Form.controls['Pharmacy_Name'].setValue(this.deactPharmacyDetails.pharmacyDetailItems[0].pharmacyName);
          this.Pharmacy_Form.controls['Zip_Code'].setValue(this.deactPharmacyDetails.pharmacyDetailItems[0].storeAddress.zip1);
          this.Pharmacy_Form.controls['Distance'].setValue('25');
          this.onSubmitPharmacy(this.Pharmacy_Form.value);
        }
      }




    }

    else {

      this.cancelRegCheck = sessionStorage.getItem('cancelReg');
      if (this.cancelRegCheck != null) {
        this.displayCancelPopup = true;
        sessionStorage.removeItem('cancelReg');
        sessionStorage.clear();
      }

      this.savedPrescriberId = sessionStorage.getItem('PrescriberId');
      if (this.savedPrescriberId != null) {
        this.Prescriber_NPI_Form.controls['Prescriber_NPI_Field'].setValue(this.savedPrescriberId);
      }

      this.savedPrescriberLastName = sessionStorage.getItem('PrescriberLastName');
      this.savedPrescriberFirstName = sessionStorage.getItem('PrescriberFirstName');
      this.savedPrescriberCity = sessionStorage.getItem('PrescriberCity');
      this.savedPrescriberState = sessionStorage.getItem('PrescriberState');
      this.savedPrescriberId="";
      if (this.savedPrescriberLastName != null) {
        this.Prescriber_Name_Form.controls['Last_Name'].setValue(this.savedPrescriberLastName);
      }
      if (this.savedPrescriberFirstName != null) {
        this.Prescriber_Name_Form.controls['First_Name'].setValue(this.savedPrescriberFirstName);
      }
      if (this.savedPrescriberCity != null) {
        this.Prescriber_Name_Form.controls['City'].setValue(this.savedPrescriberCity);
      }
      if (this.savedPrescriberState != null) {
        this.Prescriber_Name_Form.controls['State'].setValue(this.savedPrescriberState);
      }

      this.savedPharmacyName = sessionStorage.getItem('PharmacyName');
      this.savedPharmacyZipCode = sessionStorage.getItem('PharmacyZipCode');
      this.savedPharmacyDistance = sessionStorage.getItem('PharmacyDistance');

      if (this.savedPharmacyName != null) {
        this.Pharmacy_Form.controls['Pharmacy_Name'].setValue(this.savedPharmacyName);
      }
      if (this.savedPharmacyZipCode != null) {
        this.Pharmacy_Form.controls['Zip_Code'].setValue(this.savedPharmacyZipCode);
      }
      if (this.savedPharmacyDistance != null) {
        this.Pharmacy_Form.controls['Distance'].setValue(this.savedPharmacyDistance);
      }

      this.savedPrescriberUserRole = sessionStorage.getItem('selectedPrescriberRole');
      if (this.savedPrescriberUserRole != null) {
        if (this.savedPrescriberUserRole == '10') {
          this.Prescriber_Role.controls['prescriberRoleValue'].setValue('1');
        }
        else if (this.savedPrescriberUserRole == '20') {
          this.Prescriber_Role.controls['prescriberRoleValue'].setValue('2');
        }
      }

      this.savedPharmacyUserRole = sessionStorage.getItem('selectedPharmacyRole');
      if (this.savedPharmacyUserRole != null) {
        if (this.savedPharmacyUserRole == '11') {
          this.Pharmacy_Role.controls['pharmacyRoleValue'].setValue('1');
        }

        else if (this.savedPharmacyUserRole == '13') {
          this.Pharmacy_Role.controls['pharmacyRoleValue'].setValue('2');
        }
      }
      if (sessionStorage.getItem('supportingRole') != null) {
        this.sh = sessionStorage.getItem('supportingRole');
      }
      else if (sessionStorage.getItem('supportingRole') == null) {
        this.sh = '2';
      }

      if (sessionStorage.getItem('supportingRoleTabValue') != null) {
        this.selectedTab = sessionStorage.getItem('supportingRoleTabValue');
      }
      else if (sessionStorage.getItem('supportingRoleTabValue') == null) {
        this.selectedTab = 'prescriberNPI';
      }



    }



  }

  @ViewChild("NameTemplate", { static: true })
  NameTemplate?: TemplateRef<any>

  @ViewChild("AddressTemplate", { static: true })
  AddressTemplate?: TemplateRef<any>

  @ViewChild("NPITemplate", { static: true })
  NPITemplate?: TemplateRef<any>

  @ViewChild("actionPrescriberSearchSelect", { static: true })
  actionPrescriberSearchSelect?: TemplateRef<any>

  @ViewChild("actionPharmacySearchSelect", { static: true })
  actionPharmacySearchSelect?: TemplateRef<any>

  @ViewChild("PharmacyNameTemplate", { static: true })
  PharmacyNameTemplate?: TemplateRef<any>

  @ViewChild("PharmacyAddressTemplate", { static: true })
  PharmacyAddressTemplate?: TemplateRef<any>

  @ViewChild("DistanceTemplate", { static: true })
  DistanceTemplate?: TemplateRef<any>


  Prescriber_NPI_Form = this.fb.group({
    Prescriber_NPI_Field: ['', [Validators.pattern('[0-9]*$')]]
  });
  Prescriber_Role = this.fb.group({
    prescriberRoleValue: ['0']
  });
  Pharmacy_Role = this.fb.group({
    pharmacyRoleValue: ['0']
  });
  // supportingRole = this.fb.group({
  //   supportingRoleValue:['2']
  // });

  // get Prescriber_NPI_Field(){
  //   return this.Prescriber_NPI_Form.get('Prescriber_NPI_Field');
  // }
  Prescriber_Name_Form = this.fb.group({
    Last_Name: ['', [Validators.pattern('[A-Za-z]*$')]],
    First_Name: ['', [Validators.pattern('[A-Za-z]*$')]],
    City: ['', [Validators.pattern('[A-Za-z]*$')]],
    State: ['select']
  });
  Pharmacy_Form = this.fb.group({
    Pharmacy_Name: [''],
    Zip_Code: ['', [Validators.required, Validators.pattern('[0-9]*$')]],
    Distance: ['5']
  });
  public isChecked: boolean = false;
  public sh: any;
  public navSwitch: any;

  filterStatus: number = 0;



  public imagepath = "file:///C:/Users/kmadaiah/Desktop/u407.jpeg"

  show: boolean = true;
  insideTheBox() {
    this.show = false;
  }
  outsideTheBox() {
    this.show = true;
  }

  showTab(tab: string) {
    this.selectedTab = tab;
  }

  underline(elementID: string) {
    const div = document.getElementById(elementID);
    div?.classList.add('underlined');
  }

  removeUnderline(elementID: string) {
    const div = document.getElementById(elementID);
    div?.classList.remove('underlined');
  }

  noSearchResult: boolean = false;

  openNosearchResultPopup() {
    this.noSearchResult = true;
  }

  closeNosearchResultPopup() {
    this.noSearchResult = false;
  }

  getMinValue(a: number, b: number): number {
    return Math.min(a, b);
  }

  private loadPrescriberSearchItems(rawData: PrescriberSearchItem[]): void {
    this.prescriberSearchHistory = [];
    rawData.forEach((item: PrescriberSearchItem) => {
      const row: TableRowData<PrescriberSearchItem> = new TableRowData<PrescriberSearchItem>();

      const firstCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      firstCell.data = item;
      firstCell.template = this.NameTemplate;
      row.cells.push(firstCell);

      const secondCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      secondCell.data = item;
      secondCell.template = this.AddressTemplate;
      row.cells.push(secondCell);

      const thirdCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      thirdCell.data = item;
      thirdCell.template = this.NPITemplate;
      row.cells.push(thirdCell);

      const fourthCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      fourthCell.data = item;
      fourthCell.template = this.actionPrescriberSearchSelect;
      row.cells.push(fourthCell);

      this.prescriberSearchHistory.push(row);

    });
  }

  private loadPharmacySearchItems(rawData: Pharmacy[]): void {
    this.pharmacySearchHistory = [];
    rawData.forEach((item: Pharmacy) => {
      const row: TableRowData<Pharmacy> = new TableRowData<Pharmacy>();
      const firstCell: TableCellData<Pharmacy> = new TableCellData();
      firstCell.data = item;
      firstCell.template = this.PharmacyNameTemplate;
      row.cells.push(firstCell);

      const secondCell: TableCellData<Pharmacy> = new TableCellData();
      secondCell.data = item;
      secondCell.template = this.PharmacyAddressTemplate;
      row.cells.push(secondCell);
      console.log(secondCell);
      const thirdCell: TableCellData<Pharmacy> = new TableCellData();
      thirdCell.data = item;
      thirdCell.template = this.DistanceTemplate;
      row.cells.push(thirdCell);

      const fourthCell: TableCellData<Pharmacy> = new TableCellData();
      fourthCell.data = item;
      fourthCell.template = this.actionPharmacySearchSelect;
      row.cells.push(fourthCell);

      this.pharmacySearchHistory.push(row);
    });
    // console.log("prescriber array");
    // console.log(this.prescriberSearchHistory);
  }


  onPrescriberHeaderClick(headerClickEvent: TableClickEvent<TableHeaderCellData>) {
    if (headerClickEvent.cell != undefined) {
      this.sortPrescriber();
      this.loadPrescriberSearchItems(this.getPrescriberCurrentPageMembers());
    }
  }
  onPharmacyHeaderClick(headerClickEvent: TableClickEvent<TableHeaderCellData>) {
    if (headerClickEvent.cell != undefined) {
      this.sortPharmacy();
      this.loadPharmacySearchItems(this.getPharmacyCurrentPageMembers());
    }
  }

  private sortPrescriber(): void {
    this.prescriberList.sort((member1: PrescriberSearchItem, member2: PrescriberSearchItem) => {
      if (this.firstHeader.sortAscending) {
        const result = member1.firstName.localeCompare(member2.firstName);
        return result !== 0 ? result : member1.lastName.localeCompare(member2.lastName);
      } else {
        const result = member2.firstName.localeCompare(member2.firstName);
        return result !== 0 ? result : member1.lastName.localeCompare(member2.lastName);
      }
    });
  }

  private sortPharmacy(): void {
    this.pharmacyList.sort((member1: Pharmacy, member2: Pharmacy) => {
      if (this.firstHeader.sortAscending) {
        const result = member1.pharmacyName.localeCompare(member2.pharmacyName);
        return result !== 0 ? result : member1.pharmacyName.localeCompare(member2.pharmacyName);
      } else {
        const result = member2.pharmacyName.localeCompare(member2.pharmacyName);
        return result !== 0 ? result : member1.pharmacyName.localeCompare(member2.pharmacyName);
      }
    });
  }

  private getPrescriberCurrentPageMembers(): PrescriberSearchItem[] {
    this.totalEntries = this.prescriberList.length;
    if (this.prescriberList.length <= this.recordsPerPage) {
      return this.prescriberList;
    }
    else {
      const startIndex: number = (this.currentPageNumber - 1) * this.recordsPerPage;
      const endIndex: number = (this.currentPageNumber * this.recordsPerPage);
      let result = this.prescriberList.slice(startIndex, endIndex);
      return result;
    }

  }

  private getPharmacyCurrentPageMembers(): Pharmacy[] {
    this.totalEntries = this.pharmacyList.length;
    if (this.pharmacyList.length <= this.recordsPerPage) {
      return this.pharmacyList;
    }
    else {
      const startIndex: number = (this.currentPageNumber - 1) * this.recordsPerPage;
      const endIndex: number = (this.currentPageNumber * this.recordsPerPage);
      let result = this.pharmacyList.slice(startIndex, endIndex);
      return result;
    }

  }

  clearClick1() {
    this.Prescriber_NPI_Form.get('Prescriber_NPI_Field')?.setValue('');
  }
  clearClick2() {
    this.Prescriber_Name_Form.get('City')?.setValue('');
    this.Prescriber_Name_Form.get('First_Name')?.setValue('');
    this.Prescriber_Name_Form.get('Last_Name')?.setValue('');
    this.Prescriber_Name_Form.get('State')?.setValue('select');

  }

  clearClick3() {
    this.Pharmacy_Form.get('Distance')?.setValue('5');
    this.Pharmacy_Form.get('Pharmacy_Name')?.setValue('');
    this.Pharmacy_Form.get('Zip_Code')?.setValue('');
  }

  clearSearchHistory() {
    this.prescriberList = [];
    this.pharmacyList = [];
    this.totalPagesCount = -1;
  }
  clearSessionStorage() {
    sessionStorage.removeItem('PrescriberId');
    sessionStorage.removeItem('PrescriberLastName');
    sessionStorage.removeItem('PrescriberFirstName');
    sessionStorage.removeItem('PrescriberCity');
    sessionStorage.removeItem('PrescriberState');
    sessionStorage.removeItem('selectedPrescriberRole');
    sessionStorage.removeItem('selectedPharmacyRole');

  }

  closeAllPopups() {
    this.showErrorPopup = false;
    this.roleNotSelected = false;
    this.noSearchResult = false;
  }

  clearPrescriberSubmittedHistory() {
    this.submittedNPIForm = false;
    this.submittedNPINameForm = false;

  }

  clearPharmacySubmittedHistory() {
    this.submittedPharmacyForm = false;
  }

  clearPrescriberPharmacySS() {
    if (this.sh == 0) {
      this.clearClick1();
      this.clearClick2();
      this.clearSessionStorage();
      this.clearPrescriberSubmittedHistory();
      this.clearSearchHistory();
    }
    if (this.sh == 1) {
      this.clearClick3();
      this.clearSessionStorage();
      this.clearPharmacySubmittedHistory();
      this.clearSearchHistory();
    }
    this.closeAllPopups();

  }
  blurBorder: boolean = false;
  setBlueBorder() {
    this.blurBorder = true;
  }
  removeBlueBorder() {
    this.blurBorder = false;
  }


  onSubmitPrescriberNPI(value: any) {
    this.closeAllPopups();
    if (this.sh != '2') {

      if (sessionStorage.getItem('supportingRoleTabValue') != null) {
        sessionStorage.removeItem('supportingRoleTabValue')
      }
      sessionStorage.setItem('supportingRoleTabValue', this.selectedTab);

      //code to clear any previous selected roles if any
      if (sessionStorage.getItem('selectedPrescriberRole') != null) {
        sessionStorage.removeItem('selectedPrescriberRole');
      }

      if (sessionStorage.getItem('supportingRole') != null) {
        sessionStorage.removeItem('supportingRole');
      }

      //code for session storage

      if (this.sh != '2') {
        sessionStorage.setItem('supportingRole', this.sh);
      }

      if (this.Prescriber_Role.value?.prescriberRoleValue == '1') {
        sessionStorage.setItem('selectedPrescriberRole', '10');
      }
      else if (this.Prescriber_Role.value?.prescriberRoleValue == '2') {
        sessionStorage.setItem('selectedPrescriberRole', '20');
      }


      this.submittedNPIForm = true;
      sessionStorage.clear;
      sessionStorage.setItem('PrescriberId', value?.Prescriber_NPI_Field);

      if (this.Prescriber_NPI_Form.valid) {
        //clear previous pharmacy/prescriber Search results
        this.clearSearchHistory();

        var prescriber = new SteponeNPISearchRequest();
        prescriber.prescriberId = value?.Prescriber_NPI_Field;
        //prescriber.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;
        prescriber.firstName="";
        prescriber.lastName="";
        prescriber.prescriberCity="";
        prescriber.prescriberState="";

        this.prescriberSearchHistory = [];
        this.registrationService.postPrescriberNPI(prescriber).then((data: SteponeNPISearchResponse) => {
          debugger;
          //console.log(data);
          if (data.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {

            //clear the required validation since sucess code 200
            this.submittedNPIForm = false;

            this.prescriberList = data.prescriberSearchItems;
            //code to remove npis which are not of qualifier "01"
            for (let i = 0; i < this.prescriberList.length; i++) {
              {
                for (let j = 0; j < this.prescriberList[i].identifier.length; j++) {
                  if (this.prescriberList[i].identifier[j].qualifier != 'NPI') {
                    this.prescriberList[i].identifier.splice(j, 1);
                    j = j - 1;
                  }
                }
              }
            }
            ////code to lowercase address1 and city
            for (let i = 0; i < this.prescriberList.length; i++) {
              this.prescriberList[i].address.address1 = this.prescriberList[i].address.address1?.toLowerCase();
              this.prescriberList[i].address.city = this.prescriberList[i].address.city.toLocaleLowerCase();
            }
            // this.sortPrescriber();


            this.prescriberList = this.prescriberList.slice(0, 100);
            this.currentPageNumber = 1;
            this.updateTotalPrescriberPagesCount();
            this.loadPrescriberSearchItems(this.getPrescriberCurrentPageMembers());
          } else if (data.status.respCode == APPCONSTANTS.API_RESPONSE_NO_RECORDS) {
            // this.modalService.dismissAll();
            this.openNosearchResultPopup()
            this.prescriberList = [];
            this.currentPageNumber = 1;
            this.totalPagesCount = 0;
            //console.log("Results Not found");
          }
        }, (error: any) => {
          // this.modalService.dismissAll();
          // this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
        });
      }
    }
    else {
      this.roleNotSelected = true;
      window.scrollTo(0, 0);
    }
  }

  onSubmitPrescriberNPIName(value: any) {
    this.closeAllPopups();
    if (this.sh != '2') {
      if (sessionStorage.getItem('supportingRoleTabValue') != null) {
        sessionStorage.removeItem('supportingRoleTabValue')
      }
      sessionStorage.setItem('supportingRoleTabValue', this.selectedTab);

      //code to clear any previous selected roles if any
      if (sessionStorage.getItem('selectedPrescriberRole') != null) {
        sessionStorage.removeItem('selectedPrescriberRole');
      }
      if (sessionStorage.getItem('supportingRole') != null) {
        sessionStorage.removeItem('supportingRole');
      }

      //code for session storage

      if (this.sh != '2') {
        sessionStorage.setItem('supportingRole', this.sh);
      }
      if (this.Prescriber_Role.value?.prescriberRoleValue == '1') {
        sessionStorage.setItem('selectedPrescriberRole', '10');
      }
      else if (this.Prescriber_Role.value?.prescriberRoleValue == '2') {
        sessionStorage.setItem('selectedPrescriberRole', '20');
      }

      this.submittedNPINameForm = true;
      this.prescriberState = value?.State;

      sessionStorage.clear;
      sessionStorage.setItem('PrescriberLastName', value?.Last_Name);
      sessionStorage.setItem('PrescriberFirstName', value?.First_Name);
      sessionStorage.setItem('PrescriberCity', value?.City);
      sessionStorage.setItem('PrescriberState', value?.State);

      if (this.prescriberState != "select" && this.Prescriber_Name_Form.valid) {

        //clear previous pharmacy/prescriber Search results
        this.clearSearchHistory();



        var prescriber = new SteponeNPISearchRequest();
        prescriber.lastName = value?.Last_Name;
        prescriber.firstName = value?.First_Name;
        prescriber.prescriberCity = value?.City;
        prescriber.prescriberState = value?.State;
        prescriber.prescriberId="";
        this.prescriberSearchHistory = [];
        this.registrationService.postPrescriberNPI(prescriber).then((data: SteponeNPISearchResponse) => {
          //console.log(data);
          if (data.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {

            //clear the required validation since sucess code 200
            this.submittedNPINameForm = false;
            this.prescriberList = data.prescriberSearchItems;


            //code to remove npis which are not of qualifier "01"
            this.prescriberList.forEach(prescriber => {
              prescriber.identifier = prescriber.identifier.filter(id => id.qualifier == 'NPI');
            });
            // Filter prescribers to keep only those with at least one identifier with qualifier "NPI"
            this.prescriberList = this.prescriberList.filter(prescriber => prescriber.identifier.length > 0);

          ////code to lowercase address1 and city
            for (let i = 0; i < this.prescriberList.length; i++) {
              this.prescriberList[i].address.address1 = this.prescriberList[i].address.address1?.toLowerCase();
              this.prescriberList[i].address.city = this.prescriberList[i].address.city.toLocaleLowerCase();
            }
            this.sortPrescriber();
            this.prescriberList = this.prescriberList.slice(0, 100);
            this.currentPageNumber = 1;
            //console.log("Prescriber Length:" + this.prescriberList.length);
            this.updateTotalPrescriberPagesCount();
            this.loadPrescriberSearchItems(this.getPrescriberCurrentPageMembers());


          } else if (data.status.respCode == APPCONSTANTS.API_RESPONSE_NO_RECORDS) {
            this.openNosearchResultPopup()
            this.prescriberList = [];
            this.currentPageNumber = 1;
            this.totalPagesCount = 0;
          }
        }, (error: any) => {
          // this.modalService.dismissAll();
          // this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
        });
      }
    }
    else {
      this.roleNotSelected = true;
      window.scrollTo(0, 0);
    }
  }
  setPrescriber() {
    this.Prescriber_Role.controls['prescriberRoleValue'].setValue('1');
  }
  setAdmin() {
    this.Prescriber_Role.controls['prescriberRoleValue'].setValue('2');
  }
  setPharmacist() {
    this.Pharmacy_Role.controls['pharmacyRoleValue'].setValue('1');
  }
  setSpecialityPharmacist() {
    this.Pharmacy_Role.controls['pharmacyRoleValue'].setValue('2');
  }

  onSubmitPharmacy(value: any) {

    this.closeAllPopups();

    if (this.sh != '2') {
      if (sessionStorage.getItem('supportingRoleTabValue') != null) {
        sessionStorage.removeItem('supportingRoleTabValue')
      }
      sessionStorage.setItem('supportingRoleTabValue', this.selectedTab);

      //code to clear any previous selected roles if any
      if (sessionStorage.getItem('selectedPharmacyRole') != null) {
        sessionStorage.removeItem('selectedPharmacyRole');
      }
      if (sessionStorage.getItem('supportingRole') != null) {
        sessionStorage.removeItem('supportingRole');
      }

      //code for session storage

      if (this.sh != '2') {
        sessionStorage.setItem('supportingRole', this.sh);
      }
      if (this.Pharmacy_Role.value?.pharmacyRoleValue == '1') {
        sessionStorage.setItem('selectedPharmacyRole', '11');
      }
      else if (this.Pharmacy_Role.value?.pharmacyRoleValue == '2') {
        sessionStorage.setItem('selectedPharmacyRole', '13');
      }

      this.submittedPharmacyForm = true;

      sessionStorage.clear;
      sessionStorage.setItem('PharmacyName', value?.Pharmacy_Name);
      sessionStorage.setItem('PharmacyZipCode', value?.Zip_Code);
      sessionStorage.setItem('PharmacyDistance', value?.Distance);

      if (this.Pharmacy_Form.valid) {
        //clear previous pharmacy/prescriber Search results
        this.clearSearchHistory();

        var pharmacy = new SteponePharmacyRequesthemi();
        pharmacy.planSearch = new PlanSearch();
        pharmacy.address = new Address();
        pharmacy.pharmacyName = value?.Pharmacy_Name;
        pharmacy.address.zip = '' + value?.Zip_Code;
        pharmacy.searchRadius = value?.Distance;
        pharmacy.sourceSystemInstance = "";
        pharmacy.dispenserClassCode = [""];
        pharmacy.primaryProviderTypeCode = [""];
        pharmacy.planSearch.planCode = "";
        pharmacy.planSearch.planEffectiveDate = "";

        this.pharmacySearchHistory = [];
        this.registrationService.postPharmacyhemi(pharmacy).then((data: SteponePharmacyResponsehemi) => {

          //console.log(data);     
          if (data.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {

            //code to print "< 1mile" if distance is 0.0
            // for (let i = 0; i < data.pharmacySearchResponse.pharmacies.length; i++) {
            //   if (data.pharmacySearchResponse.pharmacies[0].physicalLocation.distance < 1) {
            //    data.pharmacySearchResponse.pharmacies[0].physicalLocation.distance = 0;
            //   }
            // }
            //code to lowercase address1 and city
            for (let i = 0; i < data.pharmacySearchResponse.pharmacies.length; i++) {
              data.pharmacySearchResponse.pharmacies[i].physicalLocation.address1 = data.pharmacySearchResponse.pharmacies[i].physicalLocation.address1?.toLowerCase();
              data.pharmacySearchResponse.pharmacies[i].physicalLocation.city = data.pharmacySearchResponse.pharmacies[i].physicalLocation.city?.toLowerCase();
              data.pharmacySearchResponse.pharmacies[i].pharmacyName = data.pharmacySearchResponse.pharmacies[i].pharmacyName?.toLowerCase();
            }


            this.filterlist = data.pharmacySearchResponse.pharmacies;
            debugger;

            for (let i = 0; i < this.filterlist.length; i++) {

              if (this.filterlist[i].ncpdp != undefined && this.filterlist[i].ncpdp.length < 8 && (this.filterlist[i].npi != undefined && this.filterlist[i].npi.length > 0) && (this.filterlist[i].npi != this.MailOrderNPI1 && this.filterlist[i].npi != this.MailOrderNPI2)) {
                this.pharmacyList.push(this.filterlist[i])
              }
            }
            this.sortPharmacy();

            this.pharmacyList = this.pharmacyList.slice(0, 100);
            this.currentPageNumber = 1;
            this.updateTotalPharmacyPagesCount();
            this.loadPharmacySearchItems(this.getPharmacyCurrentPageMembers());
          }
          else if (data.status.respCode == APPCONSTANTS.API_RESPONSE_NO_RECORDS) {
            // this.modalService.dismissAll();
            this.openNosearchResultPopup()
            this.pharmacyList = [];
            this.currentPageNumber = 1;
            this.totalPagesCount = 0;
          }
          else {
            this.openErrorPopup();
          }
        }, (error: any) => {
          // this.modalService.dismissAll();
          // this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
        });
      }
    }
    else {
      this.roleNotSelected = true;
      window.scrollTo(0, 0);
    }
  }

  savedSessionPrescriberDetails: any;


  onPrescriberCellClick(cellData: TableClickEvent<PrescriberSearchItem>) {
    if (this.Prescriber_Role.value?.prescriberRoleValue != '0') {
      if (this.secondClickPrevent == false) {
        this.secondClickPrevent = true;
        var prescriber1 = new SteponeInitiationRequest();
        var prescriber2 = new SteponeRegisterOptumIdStatusRequest();
        var prescriber3 = new SteponeLinkProvisionRequest();
        var prescriber4 = new SteponePrescriberDetailsRequest();
        let tempobject = sessionStorage.getItem('selfregistration');
        if (tempobject != null) {
          const userInfoSessionObject = JSON.parse(tempobject);
          if (userInfoSessionObject.currentpage.linReqId != null || userInfoSessionObject.currentpage.linReqId != '' || userInfoSessionObject.currentpage.linReqId != undefined) {

            if (this.userDeactivated == true) {
              prescriber1.linkReqID = '0';
            }
            else {
              prescriber1.linkReqID = userInfoSessionObject.currentpage.linReqId;
            }
          }
          prescriber1.optumID = userInfoSessionObject.ohid;
          prescriber2.optumID = userInfoSessionObject.ohid;
          prescriber1.uuid = userInfoSessionObject.uuid;

        }
        console.log(prescriber1.optumID, prescriber1.uuid);

        prescriber1.instcID = '';
        //prescriber1.instcID = '';
        prescriber1.npi = '' + cellData.cell?.data?.identifier[0].id;
        //prescriber4.instanceId = '' + cellData.cell?.data?.instanceId;
        prescriber4.prescriberId = '' + cellData.cell?.data?.identifier[0].id;

        prescriber1.presQualTypeID = PRESCRIBER_QUALIFIER.NPI;
        //prescriber4.prescriberId = PRESCRIBER_QUALIFIER.NPIEPIM;
        if (this.userDeactivated == true) {
          prescriber1.reActivateInd = BOOLEAN_FLAG.YES;
          prescriber1.presbrId=this.loginUserData.prescribers[0].presbrId;
        }
        else {
          prescriber1.reActivateInd = BOOLEAN_FLAG.NO;
          prescriber1.presbrId = ''
        }

        // prescriber1.presbrId = '';
        prescriber3.presbrId = '';
        prescriber3.reActivateInd = BOOLEAN_FLAG.NO;
        if (this.Prescriber_Role.value?.prescriberRoleValue != null || this.Prescriber_Role.value?.prescriberRoleValue != undefined) {
          if (this.Prescriber_Role.value?.prescriberRoleValue == '1') {
            prescriber1.role = USER_ROLE.PRESCRIBER;
          }
          else if (this.Prescriber_Role.value?.prescriberRoleValue == '2') {
            prescriber1.role = USER_ROLE.ADMIN;
          }
        }



        this.registrationService.postgetRegistrationInitiation(prescriber1).then((data: SteponeInitiationResponse) => {
          if (data.status.statusCode == 'Success') {

            prescriber3.provisioningRequestId = data.linReqId;

            console.log('1');
            //second API call  
            this.registrationHelper.getRegistrationStatus().then((resp) => {

              if (resp == true) {

                this.registrationService.postLinkProvision(prescriber3).then((data2: SteponeLinkProvisionResponse) => {

                  if (data2.status.statusCode == 'Success') {
                    let prevDataCheck1 = sessionStorage.getItem('linkProvisionResponse');
                    if (prevDataCheck1 != null) {
                      sessionStorage.removeItem('linkProvisionResponse');
                    }
                    sessionStorage.setItem('linkProvisionResponse', JSON.stringify(data2));
                    console.log('3');
                    this.registrationService.postPrescriberDetails(prescriber4).then((data3: SteponePrescriberDetailsResponse) => {
                      if (data3.status.statusCode?.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
                        let prevDataCheck1 = sessionStorage.getItem('faxDetails');
                        if (prevDataCheck1 != null) {
                          sessionStorage.removeItem('faxDetails');
                        }
                        let prevDataCheck2 = sessionStorage.getItem('userRole');
                        if (prevDataCheck2 != null) {
                          sessionStorage.removeItem('userRole');
                        }
                        sessionStorage.setItem('faxDetails', JSON.stringify(data3));
                        sessionStorage.setItem('userRole', 'prescriber');
                        this.router.navigateByUrl('/register/steptwo');
                      }
                      else {
                        let prevDataCheck1 = sessionStorage.getItem('faxDetails');
                        if (prevDataCheck1 != null) {
                          sessionStorage.removeItem('faxDetails');
                        }
                        this.router.navigateByUrl('/register/steptwo');
                        // sessionStorage.setItem('errorPrescriberScenario', 'true');
                      }

                    });
                  }
                });

              }

            });
          } else {
            this.openErrorPopup();
          }
          this.secondClickPrevent = false;
        });
      }
    }
    else {
      this.roleNotSelected = true;
      window.scrollTo(0, 0);
    }
  }

  onPharmacyCellClick(cellData: TableClickEvent<Pharmacy>) {
    debugger;

    if (this.Pharmacy_Role.value?.pharmacyRoleValue != '0') {
      if (this.secondClickPrevent == false) {
        this.secondClickPrevent = true;
        var pharmacy1 = new SteponeInitiationRequest();
        var pharmacy2 = new SteponeRegisterOptumIdStatusRequest();
        var pharmacy3 = new SteponeLinkProvisionRequest();
        var pharmacy4 = new SteponePharmacyDetailsRequest();

        pharmacy1.npi = '' + cellData.cell?.data?.npi;
        pharmacy1.instcID = 'BOOK2';
        // pharmacy1.presbrId = '';
        let tempobject = sessionStorage.getItem('selfregistration');
        if (tempobject != null) {
          const userInfoSessionObject = JSON.parse(tempobject);
          if (userInfoSessionObject.currentpage.linReqId != null || userInfoSessionObject.currentpage.linReqId != '' || userInfoSessionObject.currentpage.linReqId != undefined) {

            if (this.userDeactivated == true) {
              pharmacy1.linkReqID = '0';
              pharmacy1.presbrId = this.loginUserData.prescribers[0].presbrId;
            }
            else {
              pharmacy1.linkReqID = userInfoSessionObject.currentpage.linReqId;
              pharmacy1.presbrId = '';
            }
          }
          pharmacy1.optumID = userInfoSessionObject.ohid;
          pharmacy2.optumID = userInfoSessionObject.ohid;
          pharmacy1.uuid = userInfoSessionObject.uuid;
        }
        pharmacy1.presQualTypeID = PRESCRIBER_QUALIFIER.NPI;
        if (this.Pharmacy_Role.value?.pharmacyRoleValue != null || this.Pharmacy_Role.value?.pharmacyRoleValue != undefined) {
          if (this.Pharmacy_Role.value?.pharmacyRoleValue == '1') {
            pharmacy1.role = USER_ROLE.PHARMACIST;
          }

          else if (this.Pharmacy_Role.value?.pharmacyRoleValue == '2') {
            pharmacy1.role = USER_ROLE.SPECIALTYPHARMACIST;
          }
        }
        debugger;
        if (this.userDeactivated == true) {
          pharmacy1.reActivateInd = BOOLEAN_FLAG.YES;
        }
        else {
          pharmacy1.reActivateInd = BOOLEAN_FLAG.NO;
        }

        pharmacy3.presbrId = '';
        pharmacy3.reActivateInd = BOOLEAN_FLAG.NO;
        pharmacy4.instanceId = 'BOOK2'
        pharmacy4.pharmacyId = '' + cellData.cell?.data?.npi;
        pharmacy4.pharmacyIdQualifier = PRESCRIBER_QUALIFIER.NPI;

        this.registrationService.postgetRegistrationInitiation(pharmacy1).then((data: SteponeInitiationResponse) => {
          if (data.status.statusCode == 'Success') {

            pharmacy3.provisioningRequestId = data.linReqId;
            console.log('1');
            this.registrationHelper.getRegistrationStatus().then((resp) => {
              if (resp == true) {
                console.log('2');
                this.registrationService.postLinkProvision(pharmacy3).then((data2: SteponeLinkProvisionResponse) => {
                  if (data2.status.statusCode == 'Success') {
                    let prevDataCheck1 = sessionStorage.getItem('linkProvisionResponse');
                    if (prevDataCheck1 != null) {
                      sessionStorage.removeItem('linkProvisionResponse');
                    }
                    sessionStorage.setItem('linkProvisionResponse', JSON.stringify(data2));
                    console.log('3');
                    this.registrationService.postPharmacyDetails(pharmacy4).then((data3: SteponePharmacyDetailsResponse) => {
                      if (data3.status.statusCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
                        let prevDataCheck1 = sessionStorage.getItem('faxDetails');
                        if (prevDataCheck1 != null) {
                          sessionStorage.removeItem('faxDetails');
                        }
                        let prevDataCheck2 = sessionStorage.getItem('userRole');
                        if (prevDataCheck2 != null) {
                          sessionStorage.removeItem('userRole');
                        }
                        sessionStorage.setItem('faxDetails', JSON.stringify(data3));
                        sessionStorage.setItem('userRole', 'pharmacy');
                        this.router.navigateByUrl('/register/steptwo');
                      }
                      else {
                        let prevDataCheck1 = sessionStorage.getItem('faxDetails');
                        if (prevDataCheck1 != null) {
                          sessionStorage.removeItem('faxDetails');
                        }
                        this.router.navigateByUrl('/register/steptwo');
                        sessionStorage.setItem('errorPharmacyScenario', 'true');
                      }
                    });
                  }
                });

              }
            });
          } else {
            this.openErrorPopup();
          }
          this.secondClickPrevent = false;
        });
      }
    }

    else {
      this.roleNotSelected = true;
      window.scrollTo(0, 0);
    }
  }


  private updateTotalPrescriberPagesCount(): void {
    this.totalPagesCount = (this.prescriberList.length % this.recordsPerPage) != 0 ? Math.floor(this.prescriberList.length / this.recordsPerPage) + 1 : Math.floor(this.prescriberList.length / this.recordsPerPage);
  }

  private updateTotalPharmacyPagesCount(): void {
    this.totalPagesCount = (this.pharmacyList.length % this.recordsPerPage) != 0 ? Math.floor(this.pharmacyList.length / this.recordsPerPage) + 1 : Math.floor(this.pharmacyList.length / this.recordsPerPage);
  }

  //popup code
  showPopup: boolean = false;
  showErrorPopup: boolean = false;

  openPopup() {
    this.showPopup = true;
  }
  closePopup() {
    this.showPopup = false;
  }
  openErrorPopup() {
    this.showErrorPopup = true;
  }
  closeErrorPopup() {
    this.showErrorPopup = false;
  }
  onYesClick() {
    //write the code
    this.closePopup();
    this.openErrorPopup();


  }
  onNoClick() {
    //write the code
    this.closePopup();
  }
  closeRoleNotSelectedPopup() {
    this.roleNotSelected = false;
  }

  onPrescriberChangeEntriesPerPage(entriesPerPage: any) {
    this.recordsPerPage = entriesPerPage;
    this.currentPageNumber = 1;
    this.updateTotalPrescriberPagesCount();
    this.loadPrescriberSearchItems(this.getCurrentPrescriberPageMembers());
  }

  onPharmacyChangeEntriesPerPage(entriesPerPage: any) {
    this.recordsPerPage = entriesPerPage;
    this.currentPageNumber = 1;
    this.updateTotalPharmacyPagesCount();
    this.loadPharmacySearchItems(this.getCurrentPharmacyPageMembers());
  }

  private getCurrentPrescriberPageMembers(): PrescriberSearchItem[] {

    this.totalEntries = this.prescriberList.length;
    if (this.prescriberList.length <= this.recordsPerPage) {
      return this.prescriberList;
    }
    else {
      const startIndex: number = (this.currentPageNumber - 1) * this.recordsPerPage;
      const endIndex: number = (this.currentPageNumber * this.recordsPerPage);
      let result = this.prescriberList.slice(startIndex, endIndex);
      return result;
    }

  }

  private getCurrentPharmacyPageMembers(): Pharmacy[] {

    this.totalEntries = this.pharmacyList.length;
    if (this.pharmacyList.length <= this.recordsPerPage) {
      return this.pharmacyList;
    }
    else {
      const startIndex: number = (this.currentPageNumber - 1) * this.recordsPerPage;
      const endIndex: number = (this.currentPageNumber * this.recordsPerPage);
      let result = this.pharmacyList.slice(startIndex, endIndex);
      return result;
    }

  }

  onPrescriberPageChange(pageNumber: any) {

    this.currentPageNumber = pageNumber;
    this.loadPrescriberSearchItems(this.getCurrentPrescriberPageMembers());
  }
  onPharmacyPageChange(pageNumber: any) {

    this.currentPageNumber = pageNumber;
    this.loadPharmacySearchItems(this.getCurrentPharmacyPageMembers());
  }
  goToPrivacyLink() {
    // this.router.navigateByUrl(url);
    window.open(environment.privacyurl, "_blank");
  }
  goToTermsLink() {
    // this.router.navigateByUrl(url);
    window.open(environment.termsurl, "_blank");
  }
  goToContactusLink() {
    // this.router.navigateByUrl(url);
    window.open(environment.contactusurl, "_blank");
  }
}
