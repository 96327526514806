import { Status } from 'src/app/modals/webresponsestatus';

/***********************Prescriber Search & Prescriber Details Common response interfaces************************* */
export interface PrescriberIdentifier {
    id: string;
    qualifier: string;
    state:string;
    renewalDate:string;
    expiredDate:string;
}
export interface PrescriberAddress {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip1: string;
    zip2: string;
    zip3: string;
    country: string;
}
interface PrescriberContractPeriod {
    fromDate: string;
    thruDate: string;
}
interface PrescriberNetwork {
    id: string;
    name: string;
    activeIndicator:string;
    contractPeriod: PrescriberContractPeriod;
}
export class PrescriberDemographs{
    identifier!: PrescriberIdentifier[];
    lastName!: string;
    firstName!: string;
    middleInitial!: string;
    address!: PrescriberAddress;
    //instanceId!: string;
    npi!:string;
}
/************************************Common interfaces Ended*********************************************** */

/**********************Prescriber Search **************** */
/*export interface PrescriberSearchRootObject {
    response: PrescriberSearchResponse;
    status: Status;
}
interface PrescriberSearchResponse {
    maxRowExceededInd: boolean;
    prescriberSearchItems: PrescriberSearchItem[];
}
export interface PrescriberSearchItem extends PrescriberDemographs {
    isFavorite: boolean;
    phone1: string;
    network: PrescriberNetwork[];
    additionalFields: PrescriberSearchAdditionalField[];
}
interface PrescriberSearchAdditionalField {
    fieldDescription: string;
    fieldStringValue: string;
}*/

export interface PrescriberSearchRootObject {
    prescriberSearchItems: PrescriberSearchItem[]
    recordCount: number
    status: Status
  }
  
  export class PrescriberSearchItem extends PrescriberDemographs {
    //identifier!: Identifier[]
    phone1!: string
    additionalFields!: AdditionalField[]
    gender!: string
  }
  
  export interface Identifier {
    id: string
    qualifier: string
  }
  
  export interface Address {
    address1: string
    city: string
    state: string
    zip1: string
    zip2: string
  }
  
  export interface AdditionalField {
    fieldDescription: string
    fieldStringValue: string
  }
  

/**********************Prescriber Search Ending****************** */

/**********************Prescriber Detail**************************/
/*export interface PrescriberDetailWebResponse{
    response:PrescriberDetailResponse;
    status:Status;
}*/
/*export class PrescriberItem extends PrescriberDemographs{
    primaryDegree!:string;
    contact!:PrescriberContact;
    alternateAddresses!:PrescriberAlternateAddress[];
    selectedAlternativeAddress!:number;
    //network!:PrescriberNetwork[];
    specialty!:PrescriberSpeciatly[];
    //audit!:PrescriberAudit;
}
interface PrescriberDetailResponse{
    prescriberDetailItem:PrescriberItem;
}*/
interface PrescriberContact{
    phone1:string;
    phone1Ext:string;
    phone2:string;
    phone2Ext:string;
    fax:string;
    faxExt:string;
    email:string;
}
interface PrescriberAudit{
    addUser:string;
    addDate:string;
    addTime:string;
    changeUser:string;
    changeDate:string;
    changeTime:string;
}
export class PrescriberAlternateAddress{
    qualifier!:string;
    address!:PrescriberAddress;
    contact!:PrescriberContact;
    //audit!:PrescriberAudit;
}
interface PrescriberSpeciatly{
    code:string;
    name:string;
    //audit:PrescriberAudit;
}
/**********************Prescriber Detail**************************/

/**************************Prescriber Favorite*******************/

export interface PrescriberFavoriteWebResponse {
    presbrFavId: number;
    lastName:string;
	firstName:string;
	address:string;
    npi: string;
    presbrId: string;
    instcId: string;
} 
export interface PrescriberFavoriteResponse {
    prescriberFavoriteList: PrescriberFavoriteWebResponse[];
    presbrFavId:number;
    status: Status;
}

/******************Verify Provider response*****************************************/
export class VerifyProviderResponse {
    prescriberInfoRecent!: any[]
    prescriberInfoAll!: PrescriberInfoAll[]
    status!: Status
  }
  
  export interface PrescriberInfoAll {
    presbrId: string
    npi: string
    lastName: string
    firstName: string
    addressLine1: string
    city: string
    stateProvince: string
    postalCode: string
    countryCode: string
    primaryTelephoneNumber: string
    faxNumber: string
    approveInd: string
    defaultInd: string
    daysLeft: number
    instanceId: string
    addressLine2?: string
    presbrEdQual?: string
  }

  /**************Manage prescribers response*************************** */
  export interface PrescriberAddressBook {
    prescriberInfoList: PrescriberInfoList[]
    count: number
    status: Status
    isFavorite:boolean;
  }
  
  export interface PrescriberInfoList {
    presbrId: string
    npi: string
    lastName: string
    firstName: string
    addressLine1: string
    city: string
    stateProvince: string
    postalCode: string
    countryCode: string
    primaryTelephoneNumber: string
    faxNumber: string
    approveInd: string
    presbrStatus: number
    defaultInd: string
    daysLeft: number
    instanceId: string
    addressLine2: string
    presbrEdQual: string
    presbrCorrelationId?: string
    presbrQualId?: number
  }
  
  
  
export interface supportStaffResponse {
    presbrSupportStaff: PresbrSupportStaff[];
    status: Status;
}

export interface PresbrSupportStaff {
    accessState: string;
    dateGranted: number;
    email: string;
    firstName: string;
    instanceId: string;
    lastName: string;
    npi: string;
    presbrId: number;
    presbrPriSecRoleId: number;
    primaryTelephoneNumber: string;
    remainingHrs: number;
    remainingMins: number;
    approvebtnhover:boolean;
    denybtnhover:boolean;
}

export class PrescriberDetailWebResponse {
    recordCount!: number
    prescriberItem!: PrescriberItem
    status!: Status
  }
  export class PrescriberItem extends PrescriberDemographs{
    primaryDegree!:string;
    contact!:PrescriberContact;
    alternateAddresses!:PrescriberAlternateAddress[];
    selectedAlternativeAddress!:number;
    //network!:PrescriberNetwork[];
    specialty!:PrescriberSpeciatly[];
    //audit!:PrescriberAudit;
}
 /* export interface PrescriberItem {
    identifier: Identifier[]
    lastName: string
    firstName: string
    address: Address
    primaryDegree: string
    contact: Contact
    alternateAddresses: AlternateAddress[]
    specialty: Specialty[]
    active: boolean
    gender: string
    dob: string
  }*/
  
  export interface Identifier {
    qualifier: string
    state?: string
    renewalDate: string
    expiredDate: string
    id: string
  }
  
  export interface Address {
    address1: string
    city: string
    state: string
    zip1: string
    zip2: string
  }
  
  export interface Contact {
    phone1: string
    fax: string
  }
  
  export interface AlternateAddress {
    qualifier: string
    address: Address2
    contact: Contact2
  }
  
  export interface Address2 {
    address1: string
    city: string
    state: string
    zip1: string
    zip2?: string
  }
  
  export interface Contact2 {
    phone1: string
    fax?: string
  }
  
  export interface Specialty {
    code: string
    name: string
  }

  