import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APPCONSTANTS, PRESCRIBER_QUALIFIER } from 'src/app/constants/appconstants';
import { AddPrescriber, Prescriber, User, addPrescriberRequest, addPrescriberResponse } from 'src/app/modals/addPrescribers/addPrescriberRequest';
import { PrescriberDetailWebRequest } from 'src/app/modals/prescriberSearch/prescriberSearchweberequest';
import { PrescriberItem, PrescriberDetailWebResponse, PrescriberIdentifier, PrescriberInfoList, PrescriberSearchItem } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { StringToSentenceCase } from 'src/app/pipes/stringconvertions/stringconversions.pipe';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-add-prescribers',
  templateUrl: './add-prescribers.component.html',
  styleUrls: ['./add-prescribers.component.css']
})
export class AddPrescribersComponent implements OnInit {
  verifyPrescriberURI!: string;
  prescriberDetailItem!: PrescriberItem;
  addedPrescribersList: any = new Array();
  filterPrescribersList: any = new Array();
  npi: string = "";
  noMatchingrecordsvalue: string = "false";
  timeOutValue: string = "false";
  blnshow: boolean = false;
  prescriberDetailList!: PrescriberItem;
  selectedNPI: any;
  prescriberFaxDetails: string[] = [];
  errorMessage!: string;
  SavedAddressBkDetails!: PrescriberInfoList[];
  identifier!: PrescriberIdentifier[];
  timeoutError!: string;
  nomatchesFound!: string;
  filterStatus: string = '';
  searchHistoryLength!: number;
  userId: any;
  blnAddUserAdmin: boolean = false;
  email: any;
  blnrouteToAddPrescriber: any;
  itemCount: number = 20;
  clearAddedList: boolean = false;
  selectedRole: any;
  newListToRemoveNPIS: any;
  totalPrescriberCount:any;

  @ViewChild("addpresbrSuccess")
  addpresbrSuccessElmRef?: ElementRef<HTMLElement>;

  @ViewChild("selectFaxModal")
  selectFaxModal!: ElementRef<HTMLElement>;

  @ViewChild("PresSelectError")
  PresSelectError!: ElementRef<HTMLElement>;

  @ViewChild("maxPresbrlimitexceed")
  maxPresbrlimitexceed!: ElementRef<HTMLElement>;


  @ViewChild("noFaxSelected")
  noFaxSelected!: ElementRef<HTMLElement>;

  blnSelectFax: boolean = false;
  constructor(private stringToSentenceCase: StringToSentenceCase, private fb: FormBuilder,private router: Router, private profileService: ProfileService, private modalService: NgbModal, private prescriberSearchService: PrescriberSearchService) {
    this.verifyPrescriberURI = environment.verifyProviderUrl;
  }


  ngOnInit(): void {
    debugger;
    this.clearAddedList = true;
    this.userId = sessionStorage.getItem("userId");
    this.selectedRole = sessionStorage.getItem("selectedRole");
    this.blnrouteToAddPrescriber = sessionStorage.getItem("blnrouteToAddPrescriber");
    console.log(this.prescriberSearchHistory.length);
    if (sessionStorage.getItem('addedPrescribersList') == undefined) {
      this.addedPrescribersList = [];
    }
    else {
      this.filterPrescribersList = JSON.parse("" + sessionStorage.getItem('addedPrescribersList'));
      this.addedPrescribersList = [];
      if(this.selectedRole==20){
        this.itemCount = 20 - this.filterPrescribersList.length;
      }
        else{
          this.itemCount = this.filterPrescribersList.length;
        }
    }

    this.SavedAddressBkDetails = JSON.parse("" + sessionStorage.getItem("savedAddressBookPresdetails"));
    this.totalPrescriberCount = sessionStorage.getItem("totalPrescriberCount");
    //  this.savedPrescrbersBKArry = this.SavedAddressBkDetails;
    console.log(this.SavedAddressBkDetails);
    console.log(this.addedPrescribersList);
  }
  isItemInList(item: any): boolean {
    return this.addedPrescribersList.includes(item);
  }

  dismiss() {
    this.noMatchingrecordsvalue = "false";
    this.timeOutValue = "false";
    this.blnshow = true;
    this.modalService.dismissAll();
  }
  goto(routeUrl: string) {
    debugger;
    if (sessionStorage.getItem('addedPrescribersList') != undefined) {
      this.filterPrescribersList = JSON.parse("" + sessionStorage.getItem('addedPrescribersList'));
      if (this.filterPrescribersList.length > 0) {
        for (let i = 0; i < this.filterPrescribersList.length; i++) {
          this.itemCount = sessionStorage.getItem("itemCount") == null ? 20 : parseInt("" + sessionStorage.getItem("itemCount"));
          this.addedPrescribersList.push(this.filterPrescribersList[i]);
        }
      }
      sessionStorage.setItem("addedPrescribersList", JSON.stringify(this.addedPrescribersList));
    }
    sessionStorage.removeItem("itemCount");
    this.blnAddUserAdmin = true;
    sessionStorage.setItem("blnAddUserAdmin", "" + this.blnAddUserAdmin);
    this.itemCount = 20 - this.addedPrescribersList.length;
    sessionStorage.setItem("itemCount", "" + this.itemCount);
    this.email = sessionStorage.getItem("email");
    sessionStorage.getItem('addedPrescribersList');
    this.router.navigateByUrl("/" + routeUrl);


  }
  receiveNPI($event: PrescriberItem) {
    debugger;
    this.clearAddedList = false;
    this.prescriberDetailItem = $event;
    this.selectedNPI = this.prescriberDetailItem.npi;
    for (let i = 0; i < this.prescriberDetailItem.identifier.length; i++) {
      if (this.prescriberDetailItem.identifier[i].qualifier == 'NPI' && this.prescriberDetailItem.identifier[i].id != this.selectedNPI) {
        this.prescriberDetailItem.npi = this.prescriberDetailItem.identifier[i].id;
      }
      // else{
      //   debugger;
      //   this.blnSelectFax = false;
      //    this.errorMessage = "You already have access to this prescriber.<br> You cannot add this prescriber again."
      //    this.modalService.open(this.PresSelectError, { size: 'md' })
      // }
    }
    //  this.filterPrescribersList.push(this.addedPrescribersList);
    this.addedPrescribersList.push(this.prescriberDetailItem);
    console.log(this.addedPrescribersList);
    console.log(this.prescriberDetailItem);
    this.modalService.dismissAll();
  }

  removeAddedPrescriber(item: any) {
    debugger;
    // sessionStorage.removeItem("prescriberSearchItemList");
    console.log(item);
    for (let i = 0; i < item.identifier.length; i++) {
      if (item.identifier[i].qualifier == 'NPI') {
        this.addedPrescribersList = this.addedPrescribersList.filter((data: any) => data.identifier[i].id != item.identifier[i].id);
      }
    }
    // sessionStorage.setItem("prsbrSearchItem",JSON.stringify(this.addedPrescribersList));
    console.log(this.addedPrescribersList);
  }

  errorPopupHandling($event1: any) {
    debugger;
    console.log($event1);
    this.noMatchingrecordsvalue = $event1.matchFound;
    this.timeOutValue = $event1.timeOut;

  }

  prescriberSearchHistory($event: any) {
    debugger;
    this.searchHistoryLength = $event.length;
    console.log($event);
  }

  modalPopup() {
    this.modalService.dismissAll();
    this.modalService.open(this.noFaxSelected, { size: 'md', windowClass: 'loadingContent1' ,backdrop:'static'});
  }

  checkSavedNPI(): boolean {
    debugger;
    var flag: boolean = false;
    if(this.SavedAddressBkDetails!=undefined){ 
    for (let i = 0; i < this.SavedAddressBkDetails.length; i++) {
      if (this.SavedAddressBkDetails[i].npi == this.selectedNPI) {
        flag = true;
        break;
      }
    }
    }
    return flag;
  }
  checkAddedNPI(): boolean {
    debugger;
    var flag: boolean = false;
    for (let i = 0; i < this.addedPrescribersList.length; i++) {
      if (this.addedPrescribersList[i].npi == this.selectedNPI) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  checkNPIAdded(): boolean {
    var flag: boolean = false;
    for (let i = 0; i < this.addedPrescribersList.length; i++) {
      if (this.addedPrescribersList[i].npi == this.selectedNPI) {
        flag = true;
        break;
      }
    }
    return flag;
  }


  selectFax(data: PrescriberSearchItem): void {
    debugger;
    console.log(data);
    this.blnSelectFax = false;
    this.selectedNPI = data.identifier[0].id;
    //code to check if selected npi is already saved
    if (this.userId == undefined) {
      if (this.checkSavedNPI() == true) {
        this.blnSelectFax = false;
        this.errorMessage = "You already have access to this prescriber.<br> You cannot add this prescriber again.";
        this.modalService.open(this.PresSelectError, { size: 'md', windowClass: 'loadingContent1',backdrop:'static' });
      }

      //code to check if npi is already added
      else if (this.checkNPIAdded() == true) {
        this.blnSelectFax = false;
        this.errorMessage = "You already have access to this prescriber.<br> You cannot add this prescriber again.";
        this.modalService.open(this.PresSelectError, { size: 'md', windowClass: 'loadingContent1',backdrop:'static' });
      }
      //code to check if the npi is already added to addPrescribers array
      else if (this.checkAddedNPI() == true) {
        this.blnSelectFax = false;
        this.errorMessage = "You have already selected this prescriber.<br> You cannot add this prescriber to the queue again.";
        this.modalService.open(this.PresSelectError, { size: 'md', windowClass: 'loadingContent1',backdrop:'static' });
      }

      //code to check if there are only 20 prescribers added including saved
      else if ((this.SavedAddressBkDetails!=undefined) &&((this.addedPrescribersList.length + this.SavedAddressBkDetails.length >= 20) || (parseInt(this.totalPrescriberCount) >=20) || (this.addedPrescribersList.length + parseInt(this.totalPrescriberCount) >=20))) {
        this.modalService.open(this.maxPresbrlimitexceed, { size: 'md', windowClass: 'loadingContent1' });
      }
      else{
        this.blnSelectFax = true;
        this.getPrescriberDetail(data);
      }
    }
    //code to check if there are only 20 prescribers added including saved
    if(this.userId!=undefined){
     if (this.addedPrescribersList.length >= 20) {
      this.modalService.open(this.maxPresbrlimitexceed, { size: 'md', windowClass: 'loadingContent1' });
    }
    //for prescriber role only 1 npi needs to be selected
    else if (this.addedPrescribersList.length == 1 && this.selectedRole == 10) {
      this.modalService.open(this.maxPresbrlimitexceed, { size: 'md', windowClass: 'loadingContent1' });
    }
    //for admin role
    else if ((this.SavedAddressBkDetails!=undefined) && ((this.addedPrescribersList.length + this.SavedAddressBkDetails.length >= 20) || (parseInt(this.totalPrescriberCount) >=20) || (this.addedPrescribersList.length + parseInt(this.totalPrescriberCount) >=20))) {
      this.modalService.open(this.maxPresbrlimitexceed, { size: 'md', windowClass: 'loadingContent1' });
    }
    else if (this.checkAddedNPI() == true) {
      this.blnSelectFax = false;
      this.errorMessage = "You have already selected this prescriber.<br> You cannot add this prescriber to the queue again.";
      this.modalService.open(this.PresSelectError, { size: 'md', windowClass: 'loadingContent1',backdrop:'static' });
    }
    else {
      this.blnSelectFax = true;
      this.getPrescriberDetail(data);
    }
  }
  }
  getPrescriberDetail(data: PrescriberSearchItem) {
    debugger;
    let prescriberSearchItemList: PrescriberSearchItem[] = [];
    var faxFlag: boolean = false;
    const presbrdetailswebrequest: PrescriberDetailWebRequest = new PrescriberDetailWebRequest();
    //presbrdetailswebrequest.instanceId = data.instanceId;
    //presbrdetailswebrequest.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;
    presbrdetailswebrequest.prescriberId = data.identifier[0].id;
    this.prescriberSearchService.getPrescriberDetail(presbrdetailswebrequest).then((pbrResponse: PrescriberDetailWebResponse) => {
      debugger;
      if (pbrResponse.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
        debugger;
        prescriberSearchItemList.push(data);
        sessionStorage.setItem("prsbrSearchItem",JSON.stringify(prescriberSearchItemList));
        let temp: string | null = sessionStorage.getItem('prsbrSearchItem');
        sessionStorage.setItem("status",JSON.stringify(pbrResponse.status));
        this.errorMessage = "";
        //this.prescriberFaxDetails = [];
        this.prescriberDetailList = pbrResponse.prescriberItem;
        // code to check for fax 0
        debugger;
        if (this.prescriberDetailList.alternateAddresses.length >= 1) {
          for (let i = 0; i < this.prescriberDetailList.alternateAddresses.length; i++) {
            if (this.prescriberDetailList.alternateAddresses[i].contact.fax!=undefined && this.prescriberDetailList.alternateAddresses[i].contact.fax.length==10) {
              let faxslice = this.prescriberDetailList.alternateAddresses[i].contact.fax;
              faxslice = faxslice.slice(0, 3) + '-' + faxslice.slice(3, 6) + '-' + faxslice.slice(6);
              this.prescriberFaxDetails.push(faxslice);
              faxFlag = true;
              this.prescriberFaxDetails = [...new Set(this.prescriberFaxDetails)];
            }
          }
          this.modalService.open(this.selectFaxModal, { size: 'md',backdrop:'static' });

          if (faxFlag == false) {
            this.blnSelectFax = false;
            this.errorMessage = "We cannot find a fax associated with your prescriber's office. Contact us for assistance at 1-866-842-3278.";
            this.modalService.open(this.selectFaxModal, { size: 'md',backdrop:'static' });
          }
        }
      }


    }, (error: any) => {
      this.modalService.dismissAll();
      if (error.status.respCode == APPCONSTANTS.API_RESPONSE_NO_RECORDS) {
        sessionStorage.setItem("nomatchingrecordsvalue", "true");
        this.nomatchesFound = "" + sessionStorage.getItem("nomatchingrecordsvalue");
        this.noMatchingrecordsvalue = this.nomatchesFound;
      }
      if (error.status.respCode == "504") {
        sessionStorage.setItem("timeoutErrorvalue", "true");
        this.timeoutError = "" + sessionStorage.getItem("timeoutErrorvalue");
        this.timeOutValue = this.timeoutError;
      }
    });
  }
  
  selectedFax = this.fb.group({
    faxValue: ['', [Validators.required]],
  });
  isFormValid(): boolean {
    return this.selectedFax.valid;
  }

  addPrescribers() {
    debugger;
    let webrequest: addPrescriberRequest = new addPrescriberRequest();

    let user: User = new User();
    let addPrescriberList: AddPrescriber[] = [];
    user.firstName = "" + this.profileService.loginuser?.prescriber?.firstName;
    user.lastName = "" + this.profileService.loginuser?.prescriber?.lastName;
    user.streetAddress = "" + this.profileService.loginuser?.prescriber?.addressLine1;
    user.city = "" + this.profileService.loginuser?.prescriber?.city;
    user.state = "" + this.profileService.loginuser?.prescriber?.stateProvince;
    user.zipCode = "" + this.profileService.loginuser?.prescriber?.postalCode;
    user.phoneNumber = "" + this.profileService.loginuser?.prescriber?.primaryTelephoneNumber;
    user.faxNumber = "" + this.profileService.loginuser?.prescriber?.faxNumber;
    user.email = "" + this.profileService.loginuser?.email;
    user.npi = "" + this.profileService.loginuser?.prescriber?.npi;
    user.optumID = "" + this.profileService.loginuser?.prescriber?.optumId;
    user.uuid = "" + this.profileService.loginuser?.prescriber?.uuid;
    user.country = "";
    user.educationQualification = "";
    user.addrLine2 = "" + this.profileService.loginuser?.prescriber?.addressLine2;
    user.presbrQualTypeId = "";
    //user.instanceId = "" + this.profileService.loginuser?.prescriber?.instanceId;
    for (let i = 0; i < this.addedPrescribersList.length; i++) {
      debugger;
      let prescriber: Prescriber = new Prescriber();
      prescriber.firstName = ""+this.addedPrescribersList[i].firstName;
      prescriber.lastName = ""+this.addedPrescribersList[i].lastName;
      prescriber.streetAddress = ""+this.addedPrescribersList[i].address.address1;
      prescriber.city = ""+this.addedPrescribersList[i].address.city;
      prescriber.state = ""+this.addedPrescribersList[i].address.state;
      prescriber.zipCode = ""+this.addedPrescribersList[i].address.zip1;
      prescriber.phoneNumber = ""+this.addedPrescribersList[i].contact.phone1;
      //prescriber.faxNumber = ""+this.addedPrescribersList[i].contact.fax;
      if (this.selectedFax.value.faxValue !== "" && this.selectedFax.value.faxValue !== null) {
      let faxcleared = this.selectedFax.value.faxValue;
      faxcleared = (faxcleared ?? '').replace(/[^a-zA-Z0-9]/g, '');
       //faxcleared = faxcleared??''.slice(0, 3) + faxcleared??''.slice(4, 7) + faxcleared??''.slice(8);
      prescriber.faxNumber=""+faxcleared;
      }
      prescriber.email = "";
      prescriber.npi = ""+this.addedPrescribersList[i].identifier[0].id;
      prescriber.optumID = "";
      prescriber.uuid = "";
      prescriber.country = "";
      prescriber.educationQualification = ""+this.addedPrescribersList[i].primaryDegree;
      prescriber.addrLine2 = ""+this.addedPrescribersList[i].address.address2;
      prescriber.presbrQualTypeId = "01";
     // prescriber.instanceId = ""+this.addedPrescribersList[i].instanceId;
      let addPrescriber: AddPrescriber = new AddPrescriber();
      addPrescriber.role = "" + this.profileService.loginuser?.prescriber?.roleTypeId;
      addPrescriber.linkRequestId = "";
      addPrescriber.reActivateInd = "N";
      addPrescriber.user = user;
      addPrescriber.prescriber = prescriber;
      addPrescriberList[i] = addPrescriber;
    }
    webrequest.addPrescriberList = addPrescriberList;
    webrequest.prescribersLimit = "20";
    webrequest.verifyURL = environment.verifyProviderUrl;

    this.prescriberSearchService.addPrescriber(webrequest).then((response: addPrescriberResponse) => {
      debugger;
      //   console.log(response);
      //   for(let i=0;i<response.addprescriberslist.length;i++){
      //     debugger;
      //   if(response.addprescriberslist[i].status.statusCode=="Success"){
      //     console.log(response.addprescriberslist[i].status);
      //   }
      // }
      if (response != undefined)
        this.modalService.open(this.addpresbrSuccessElmRef, { size: 'md' ,backdrop:'static'});
    }, (error: any) => {

    }).catch((reason: any) => {

    });
  }

  changeLastpageloaded() {
    sessionStorage.setItem("lastPageBeforeReload","/verifyProvider");
  }
}
