import { environment } from 'src/environments/environment';

export enum KEY_CODE {
    RIGHT_ARROW = 39,
    LEFT_ARROW = 37,
    SPACE_BAR = 32,
    ENTER = 13
  }
export enum PROVIDERDETAIL_STATUS{
  SUCCESS=0,
  NOT_FOUND=1,
  SERVER_ERROR=2,
  MISSING_FIELDS=3,
  EDIT=4
}

export const STATES = new Map<string,string>([
  ["Alabama","AL"],["Alaska","AK"],["Arizona","AZ"],["Arkansas","AR"],["California","CA"],["Colorada","CO"],["Connecticut","CT"],
  ["Delaware","DE"],["Florida","FL"],["Georgia","GA"],["Hawaii","HI"],["Idaho","ID"],["Illinois","IL"],["Indiana","IN"],
  ["Lowa","IA"],["Kansas","KS"],["Kentucky","KY"],["Louisiana","LA"],["Maine","ME"],["Maryland","MD"],["Massachusetts","MA"],
  ["Michigan","MI"],["Minnesota","MN"],["Mississippi","MS"],["Missouri","MO"],["Montana","MT"],["Nebraska","NE"],["Nevada","NV"],
  ["New Hampshire","NH"],["New Jersey","NJ"],["New Mexico","NM"],["New York","NY"],["North Carolina","NC"],["North Dakota","ND"],
  ["Ohio","OH"],["Oklahoma","OK"],["Oregon","OR"],["Pennsylvania","PA"],["Rhode Island","RI"],["South Carolina","SC"],["South Dakota","SD"],
  ["Tennessee","TN"],["Texas","TX"],["Utah","UT"],["Vermont","VT"],["Virginia","VA"],["Washington","WA"],["West Virginia","WV"],
  ["Wisconsin","WI"],["Wyoming","WY"]
]);

export enum APPCONSTANTS {
    APP_NOT_INITIALIZED = 0,
    APP_INITIALIZED = 1,
    APP_INITIALIZATION_ERROR = 2,


    APP_LOGIN_ROLES_MISSING = 3,
    APP_LOGIN_USER_INACTIVE = 4,
    APP_LOGIN_USER_NOT_FOUND = 5,

    APP_ACCESS_CODE_RECEIVED = 6,
    APP_TOKEN_RECEIVED = 7,
    APP_REGISTRATION_LOADED = 8,
    APP_USER_SESSION_DUPLICATE =9,
    APP_LOGIN_NO_DEFAULT_PRESCRIBER = 400,
    APP_LOGIN_FORBIDDEN = 403,

    API_RESPONSE_WARNING_TXT = "warning",
    API_RESPONSE_INFO_TXT = "info",

    API_RESPONSE_ERROR_TEXT = "failure",

    API_RESPONSE_SUCCESS_TXT = "success",


    API_RESPONSE_SUCCESS_NBR = "0",
    API_RESPONSE_SUCCESS_CODE = "200",
    API_RESPONSE_NO_RECORDS = "404",

    API_RESPONSE_DB_SUCCESS_CODE = "0",
    API_RESPONSE_DB_FAILURE_CODE = "2",
    API_RESPONSE_FAVOURITEMEMBER_LIMIT_EXCEEDED = "2",
    RTB_TRAILCLAIM_TRANSACTIONTYPE = "9",
    RTB_USUALANDCUSTOMARYCHARGE = "999999.99",
    RTB_ISBENEFITINQUIRY = "Y",
    RTB_FILLNUMBER = "1",
    RTB_NUMBEROFREFILLSAUTHORIZED = "00",
    RTB_GROSSAMOUNTDUE = "999999.99",
    RTB_DAW = '0',
    RTB_SUBMITTEDINGRIDIENTCOST = "999999.99",
    RTB_CONSUMINGAPP = "RXLINK",

    EPAAUTOSAVEMILSEC=30000, //3Min
    ATTACHMENT_SIZE=4, //In MB
    ATTACHMENT_TYPES="application/pdf,image/jpeg,image/png,image/tiff",
    BI_UPLOAD_TYPE="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    RTB_HIGHTRUSTQUESTIONS="84",
    RxLink_Admin="RxLink_ADMIN",
    RxLink_PRESCRIBER="RxLink_PRESCRIBER",
    RxLink_ADVISOR="RxLink_ADVISOR",
    RxLink_SUPPORT="RxLink_SUPPORT",
    RxLink_PHARMACIST="RxLink_PHARMACIST",
    RxLink_PHARMACYTECH="RxLink_PHARMACYTECH",
    RTB_TRAILRESPONSE_PAID="p|b",
    RTB_DUR_RESPONSE_NONSOFTREJECT="LEH",
    RTB_DUR_PRFSNLSRVCCD_NONSOFTREJECT="MA",
    RTB_DUR_REASONSRVCCD_NONSOFTREJECT="ID",
    RTB_DUR_RESPONSE_SOFTREJECT="S",
    RTB_DUR_REJECT_CODE="88",
    BIN="999999",
    EnableRTB="Y",
    PCN_RTB = "ESB",
    PCN_NONRTB = "RXLINK",
    ROLES = "ROLES",
}

export enum BOOLEAN_FLAG{
    YES='Y',
    NO='N'
}

export enum PRESCRIBER_QUALIFIER{
  NPIEPIM="NPI",
  NPI="01",
  NCPDP="07",
  Empty=""
}

export enum USER_ROLE{
    NO_ROLE=0,
    ADMIN=20,
    PRESCRIBER=10,
    PHARMACIST=11,
    SUPPORT=21,
    ADVISOR=12,
    // PHARMACYTECH=13,    
    SPECIALTYPHARMACIST=13,
    SMAPPRESCRIBER=30,
    SMAPADMIN=35,
    SMAPSUPERADMIN=40,
    SMAPCLAIMSUB=45
}
export const USER_ROLE_Label = new Map<number, string>([
    [USER_ROLE.NO_ROLE, 'No Role'],
    [USER_ROLE.ADMIN, 'PCMS Admin'],
    [USER_ROLE.PRESCRIBER, 'PCMS Prescriber'],
    [USER_ROLE.SUPPORT,"Support"],
    [USER_ROLE.ADVISOR,"Advisor"],
    [USER_ROLE.SMAPPRESCRIBER,"Prescriber"],
    [USER_ROLE.SMAPADMIN,"Admin"],
    [USER_ROLE.SMAPSUPERADMIN,"Super Admin"],
    [USER_ROLE.SMAPCLAIMSUB,"Claim Submission"],
]);


export enum PersonGender{
    Male="M",
    Female="F",
    Unknown="U",
    NonBinary="N"
  }
  export const PERSONGENDER_DESC = new Map<string, string>([
    [PersonGender.Male, "Male"],
    [PersonGender.Female, "Female"],
    [PersonGender.Unknown, "Unknown"],
    [PersonGender.NonBinary, "Non-binary"]
  ]);

  export enum DRUG_QUALIFIER{
    UPC="01",
    HRI="02",
    NDC="03",
    Empty=""
  }
  export const DRUG_QUALIFIER_LABEL = new Map<string,DRUG_QUALIFIER>([
    ["01",DRUG_QUALIFIER.UPC],
    ["1",DRUG_QUALIFIER.UPC],
    ["02",DRUG_QUALIFIER.HRI],
    ["2",DRUG_QUALIFIER.HRI],
    ["03",DRUG_QUALIFIER.NDC],
    ["3",DRUG_QUALIFIER.NDC],
    ["",DRUG_QUALIFIER.Empty],
  ]);
  /*************************************************************/

  export enum VIEWALL_DATERANGE{
    DAYS_THIRTY=1,
    DAYS_SIXTY=2,
    MONTHS_SIX=3,
    YEARS_ONE=4,
    CUSTOM_DATE=5,
}

  export const VIEWALL_DATERANGE_LEBELS = new Map<number,string>([
    [VIEWALL_DATERANGE.DAYS_THIRTY, "Last 30 days"],
    [VIEWALL_DATERANGE.DAYS_SIXTY, "Last 60 days"],
    [VIEWALL_DATERANGE.MONTHS_SIX, "Last 6 months"],
    [VIEWALL_DATERANGE.YEARS_ONE, "Last year"],
    [VIEWALL_DATERANGE.CUSTOM_DATE, "Custom date"],
]);
export enum VIEWALLPA_DATERANGE{
  DAYS_THIRTY=1,
  DAYS_SIXTY=2,
  DAYS_NINETY=3,
  DAYS_ONE_EIGHTY=4,
  CUSTOM_DATE=5,
}
export const VIEWALLPA_DATERANGE_LEBELS = new Map<number,string>([
  [VIEWALLPA_DATERANGE.DAYS_THIRTY, "Last 30 days"],
  [VIEWALLPA_DATERANGE.DAYS_SIXTY, "Last 60 days"],
  [VIEWALLPA_DATERANGE.DAYS_NINETY, "Last 90 days"],
  [VIEWALLPA_DATERANGE.DAYS_ONE_EIGHTY, "Last 180 days"],
  [VIEWALLPA_DATERANGE.CUSTOM_DATE, "Custom date range"]
]);
export enum VIEWALL_SAVEDPA_STATUS{
  VIEW_ALL=1,
  VIEW_EXPIRED=2,
  VIEW_ACTIVE=3
}
export const VIEWALL_SAVEDPA_STATUS_LABELS = new Map<number, string>([
  [VIEWALL_SAVEDPA_STATUS.VIEW_ALL, "View All"],
  [VIEWALL_SAVEDPA_STATUS.VIEW_EXPIRED, "View Expired"],
  [VIEWALL_SAVEDPA_STATUS.VIEW_ACTIVE, "View Active"]
]);

export enum VIEWALL_SUBMITTEDPA_STATUS{
  ALL=1,
  APPROVED=2,
  DENIED=3,
  CANCELLED=4,
  PENDING=5
}
export const VIEWALL_SUBMITTEDPA_STATUS_LABELS = new Map<number,string>([
  [VIEWALL_SUBMITTEDPA_STATUS.ALL,"All Statuses"],
  [VIEWALL_SUBMITTEDPA_STATUS.APPROVED, "Approved"],
  [VIEWALL_SUBMITTEDPA_STATUS.DENIED, "Denied"],
  [VIEWALL_SUBMITTEDPA_STATUS.CANCELLED, "Cancelled"],
  [VIEWALL_SUBMITTEDPA_STATUS.PENDING, "Pending"]
]);

export enum VIEWALL_NOTIFICATIONS_STATUS{
  ALL=1,
  APPROVED=2,
  DENIED=3,
  CANCELLED=4,
  PENDING=5
}
export const VIEWALL_NOTIFICATIONS_STATUS_LABELS = new Map<number,string>([
  [VIEWALL_SUBMITTEDPA_STATUS.ALL,"All Statuses"],
  [VIEWALL_SUBMITTEDPA_STATUS.APPROVED, "Approved"],
  [VIEWALL_SUBMITTEDPA_STATUS.DENIED, "Denied"],
  [VIEWALL_SUBMITTEDPA_STATUS.CANCELLED, "Cancelled"],
  [VIEWALL_SUBMITTEDPA_STATUS.PENDING, "Saved PA Expiring"]
]);

  export enum PRESCRIBERDETAIL_STATUS{
    SUCCESS=0,
    NOT_FOUND=1,
    SERVER_ERROR=2,
    MISSING_FIELDS=3,
    EDIT=4
  }
  export const PRESCRIBER_QUALIFIER_LABEL = new Map<string, PRESCRIBER_QUALIFIER>([
    ["01",PRESCRIBER_QUALIFIER.NPI],
    ["1", PRESCRIBER_QUALIFIER.NPI],
    ["07", PRESCRIBER_QUALIFIER.NCPDP],
    ["7", PRESCRIBER_QUALIFIER.NCPDP]
  ]);
  /*************************************************************/

  export enum EPA_TRANSACTIon_STATUS{
    INTERNAL_SERVER_ERROR="1",
    INITIATION_SUCCESS_RESPCODE="000",
    INITIATION_PA_IMMEDIATE_CLOSED="3",
    INITIATION_DB_ERROR=4,
    SOMETHING_WRONG="0",
    CANCELLATION_DB_ERROR=4,
    CANCELLATION_DB_ERROR_WITHREASON=3,
    SERVICE_TIMEOUT="Timeout",
    POOLING_TIME_EXCEEDED="2",
    POOLING_QS_RECEIVED="0",
    POOLING_RESP_RECEIVED="0",
    CANCELLATION_FAILED_REFID_MISMATCH=1,
    PRIORAUTH_DENIED="1",
    PRIORAUTH_CLOSED="3",
    PRIORAUTH_ERROR="generic",
    PRIORAUTH_CANCELLED="4",
    PlanNotSupported="Electronic Prior Authorization case cannot be initiated for this member as the plan is not supported",
    ContactRx="Please contact OptumRx at 1-800-711-4555 for more information"
  }

  export enum EPA_STATUS{
    PA_NOT_STARTED=0,
    /********1st Part of EPA process*****************/
    PA_INITIATION=1,
    PA_INITIATION_SUCCESS=2,
    /********2nd Part of EPA process*****************/
    PA_QUESTIONNARIE_CHECK=3,
    PA_QUESTIONNARIE_RETRIVED=4,
    PA_QUESTIONNARIE_TIMELINE=5,
    /********3rd Part of EPA process*****************/
    PA_SUBMISSION=6,
    PA_SUBMISSION_SUCCESS=7,
    /********4rth Part of EPA process****************/
    PA_STATUS_RETRIVED=8
  }

  export enum CLAIM_STATUS_TYPE{
    UN_SAVED=0,
    SAVED=1,
    RE_EXECUTE=2,
    SUBMITTED_PAID=3,
    REVERSED_PAID=4,
    REMOVED=5,
    SUBMITTED_REJECTED=6,
    REVERSED_REJECTED=7,
    SUBMITTED_DUPLICATE=8
  }
  export enum DUR{
    NO_ADMINITRATION=0,
    LESSTHAN_ONEHOUR=11,
    ONE_TO_TWOHOUR=12,
    TWO_TO_THREEHOUR=13,
    THREE_TO_FOURHOUR=14,
    GREATERTHAN_FOURHOUR=15
}

  export const DUR_LABEL = new Map<number,string>([
    [DUR.NO_ADMINITRATION,"DUR-PPS 00 - No Administration"],
    [DUR.LESSTHAN_ONEHOUR,"DUR-PPS 11 - < 1 hour"],
    [DUR.ONE_TO_TWOHOUR,"DUR-PPS 12 - 1-2 hours"],
    [DUR.TWO_TO_THREEHOUR,"DUR-PPS 13 - 2-3 hours"],
    [DUR.THREE_TO_FOURHOUR,"DUR-PPS 14 - 3-4 hours"],
    [DUR.GREATERTHAN_FOURHOUR,"DUR-PPS 15 - > 4 hours"]
]);

  export const DUR_LABELTO_VALUE = new Map<string,string>([
    ["DUR-PPS 00 - No Administration", "00"],
    ["DUR-PPS 11 - < 1 hour", "11"],
    ["DUR-PPS 12 - 1-2 hours", "12"],
    ["DUR-PPS 13 - 2-3 hours", "13"],
    ["DUR-PPS 14 - 3-4 hours", "14"],
    ["DUR-PPS 15 - > 4 hours", "15"]
]);

export enum COVERAGE_MESSAGE_TYPE{
  OPIOID_MORPHINE="This Opioid medication as prescribed may not be covered as patient exceeds Cumulative Morphine Milligram Equivalent dosing plan limit.",
  OPIOID_PRENATAL="This medication as prescribed may not be covered as patient claims show concurrent use of an opioid and a pregnancy drug (e.g., prenatal vitamin).",
  OPIOID_BENZODIAZEPINE ="This medication as prescribed may not be covered as patient claims show concurrent use of a benzodiazepine and an opioid.",
  OPIOID_MAT ="This medication as prescribed may not be covered as patient claims show active use of Medication Assisted Treatment.",
  OPIOID_ACETAMINOPHEN ="This opioid medication as prescribed may not be covered because patient exceeds 4 grams of acetaminophen. Consider decreasing dosage.",
  PRODUCT_EXCLUDED_IN_PLAN1="This medication may be excluded by the patient's prescription benefit plan.",
  PRODUCT_EXCLUDED_IN_PLAN2="Product may be excluded under the member's plan. Consider an alternative if provided.",
  PRODUCT_EXCLUDED_IN_PLAN3="Drug may be excluded under the patient's plan.",
  PRODUCT_NOT_COVERED_IN_PLAN="Drug may not be covered under the patient's plan.",
  BPG_NOT_MAPPEDTO_CARRIER="Please call the help desk at 1-800-797-9791.  The Bin, PCN, and Group needs to be setup for this carrier.",
  COVERAGE_ERROR="An error occurred. Please call customer service at 1-866-842-3278.",
  ERROR_POPUP="An error occured. Please try again.",
  OPIOID_NOT_COVERED1="This opioid medication as prescribed exceeds the plan allowed limits. Consider lowering the quantity or the day supply if patient is new to opioid medication.",
  OPIOID_NOT_COVERED2="This medication as prescribed may not be covered as patient claims show use of another long-acting opioid drug.",
  OPIOID_NOT_COVERED3="This opioid medication as prescribed exceeds the plan allowed cumulative morphine milligram equivalent dosage. Prescribe lowest effective dosage. Consider Naloxone for safety."

}

export const DUR_PROFESSIONAL_SERVICECODES = new Map<string, string>([
  ["M0", "M0 - Prescriber consulted"],
  ["P0", "P0 - Patient consulted"],
  ["R0", "R0 - Pharmacist consulted other source"]
]);

export const DUR_RESULTOF_SERVICECODES = new Map<string, string>([
  ["1B", "Filled Prescription As Is"],
  ["1G", "Filled with Prescriber Approval"]
]);

export enum ROLES{
  prescriber=0,
  admin=1,
  advisor=2,
  pharmacist=3,
  specialtypharmacist=4
}

export enum PAGE_TYPE{
  SAVEDPAS=0,
  SUBMITTEDPAS=1,
  SAVEDCLAIMS=2,
  SUBMITTEDCLAIMS=3,
  BOOKMARKCLAIMS=4,
 // PAIDTRIALCLAIMS=5
}
export class ViewAllRouteState{
  pageType!:PAGE_TYPE
}
export enum MANAGE_PRESCRIBER_STATUS{
  DEACTIVATED=108,
  PENDING_EXPIRED=101,
  ADDED_PRESCRIBER=100,
  DENIED=105,
  REACTIVATE_STATUS1="No Prescirber(s) are found to change the status/Already Progress started",
  NORMAL=102,
  REMOVED_ADDED_PRESCRIBER=107,
  DENIED_UPDATE_STATUS=106,
  CANCEL_SUCCESS="Prescriber status updation successfull",
  PRESCRIBER_NOTFOUND="No Prescriber(s) found",
  REQUESTPINFLAG4=4,
  REQUESTPINFLAG3=3,
  PIN_EXCEEDED_DESC="PIN Retry attempts exceed for this user",
  CUSTOM_CODE0="0",
  CUSTOM_CODE2="2",
  PRIMARY_ADDRESS="primary",
  ALTERNATE_ADDRESS="alternate ",
  DB_CODE1="1",
  DB_CODE3="3",
  DB_CODE4="4",
  DB_CODE5="5",  
  DB_CODE6="6",
  DB_CODE7="7",

  VEFIFY_DENIED="Prescriber Denied successfully!!",
  VEFIFY_APPROVED="Prescriber Approved successfully!!",
}
