<advisorhome *ngIf="userrole==12"></advisorhome>
<pharmacyhome *ngIf="userrole==11"></pharmacyhome>
<specialty-pharmacyhome *ngIf="userrole==13"></specialty-pharmacyhome>
<providerhome *ngIf="userrole==10 || userrole==20 || userrole==21"></providerhome>

<div class="container-fluid">
    <div class="row" *ngIf="userrole==12">
        <div class="col-lg-10 col-xl-8 offset-xl-2 offset-lg-1">
            <div class="card bg-white mb-2">
                <div class="card-body p-4">
                    <p class="m-0">
                        As an advisor, certain functionality, including the initiation of prior authorizations is not
                        available for your
                        role at this time.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="userrole==11">
        <div class="col-lg-10 col-xl-8 offset-xl-2 offset-lg-1">
            <div class="card bg-white mb-2">
                <div class="card-body p-4">
                    <p class="m-0">
                        As a pharmacist, certain functionality, including the initiation of prior authorizations is not
                        available for your
                        role at this time.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="font-size: 16px;" *ngIf="userrole==10 || userrole==20 || userrole==21 || userrole==13">
        <div class="col-lg-10 col-xl-8 offset-xl-2 offset-lg-1">
            <ul class="nav nav-tabs nav-justified" role="tablist">
                <li class="nav-item">
                    <a class="nav-link text-center" [ngClass]="{'active':currenttab==1}"
                        aria-controls="PA_Notifications" href="javascript:void(0)" (click)="currenttab=1"
                        style="align-items: center;display: flex;justify-content: center;">
                        Notifications&nbsp;
                        <span class="badge badge-primary ng-binding"
                            style="top:-1px;border-radius:3px;background:#0470c5;padding:5px 8px;">{{newNotificationsCount}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-center" [ngClass]="{'active':currenttab==2}" aria-controls="PA_History"
                        href="javascript:void(0)" (click)="currenttab=2">Submitted Prior Authorizations</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-center" [ngClass]="{'active':currenttab==3}" aria-controls="Saved_PA"
                        href="javascript:void(0)" (click)="currenttab=3">Saved Prior Authorizations</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="row" *ngIf="userrole==10 || userrole==20 || userrole==21 || userrole==13">
        <div class="col-lg-10 col-xl-8 offset-xl-2 offset-lg-1">
            <div class="tab-content" *ngIf="currenttab==1">
                <!-- <h2>Notifications</h2> -->
                <!-- Load valid Notification history component here. -->
                <viewallnotifications [role]="userrole" (newNotifications)="getnewNotificationsCount($event)">
                </viewallnotifications>
            </div>

            <div class="tab-content" *ngIf="currenttab==2" role="tabpanel" aria-labelledby="submittedpahistory">
                <div class="row" style="margin: 1px;">
                    <div class="col-md-7 col-xs-6" style="padding-left:20px;padding-right:0px">
                        <!--- <h2 class="ng-binding" style="color: #626f7d !important;">Submitted prior authorizations</h2>-->
                        <!--<h2 class="accordion-header" id="flush-headingTwo">
                        <button class="accordion-button collapsed" [ngClass]="{'active':activeTab==2}" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo" (click)="activeTab==2?activeTab=0:activeTab=2">
                            Submitted prior authorizations
                        </button>
                      </h2>-->
                        <h2 style="font-family: Frutiger-Roman, Helvetica, Arial, sans-serif;color: #424249 !important;font-size: 24px;margin-bottom: 10px;">Submitted prior authorizations</h2>
                        <p class="large">Below are the most recent submitted prior authorizations
                            within<br> the past 30 days.<span (click)="gotoViewAllSubmittedPA()" *ngIf="userrole==20"
                                class="member_search_button">View all submitted PA
                            </span></p>
                    </div>
                    <div class="col-md-5 col-xs-6" style="float:right" *ngIf="userrole==10||userrole==13">
                        <span class="pull-right"
                            style="display:inline-block;width:100%;max-width:350px;margin-top: 5px;">
                            <div class="ng-scope">
                                <button class="btn btn-secondary pull-right ng-scope" style="height:44px"
                                    (click)="gotoViewAllSubmittedPA()">View all
                                    submitted PAs</button>
                            </div>

                        </span>
                    </div>
                    <div class="col-md-5 col-xs-6" style="float:right" *ngIf="userrole==20">
                        <prescriberfilter [reqfrom]="reqFrom" (presbrId)="onNpiSelected($event)">

                        </prescriberfilter>

                    </div>
                    <section>
                        <submittedpahistory [newData]="sourceData" [maxResults]="maxHistoryCount"></submittedpahistory>
                    </section>
                    <div class="col-xs-12" style="padding-right: 0px;margin-bottom: 30px;">
                        <button class="btn btn-secondary float-end fontbold m-3" (click)="gotoViewAllSubmittedPA()">View
                            all submitted PA
                        </button>
                    </div>
                </div>
            </div>
            <!-- <a tabindex="0" class="btn btn-secondary float-end rounded-pill" aria-label="View all PAs in progress" (click)="gotoViewAllSavedPA()">View all Saved PAs
            </a>-->
            <div class="tab-content" *ngIf="currenttab==3" role="tabpanel" aria-labelledby="savedpahistory">
                <div class="row" style="margin: 1px;">

                    <div class="col-md-7 col-xs-6" style="padding-left:20px;padding-right:0px">

                        <!--<h2 class="accordion-header" id="flush-headingThree">
                        <button class="accordion-button collapsed" [ngClass]="{'active':activeTab==3}" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree" (click)="activeTab==3?activeTab=0:activeTab=3">
                            Saved Prior Authorizations
                        </button>
                      </h2>-->
                        <h2 style="font-family: Frutiger-Roman, Helvetica, Arial, sans-serif;color: #424249 !important;font-size: 24px;margin-bottom: 10px;">Saved Prior Authorizations</h2>
                        <p class="large">Select a saved prior authorization to resume your work, or
                            resubmit <br>an expired prior authorization request.<span (click)="gotoViewAllSavedPA()" *ngIf="userrole==20"
                                class="member_search_button">View all saved PA
                            </span></p>
                    </div>
                    <div class="col-md-5 col-xs-6" style="float:right" *ngIf="userrole==10||userrole==13">
                        <span class="pull-right"
                            style="display:inline-block;width:100%;max-width:350px;margin-top: 5px;">
                            <div class="ng-scope">
                                <button class="btn btn-secondary pull-right ng-scope" style="height:44px"
                                    (click)="gotoViewAllSavedPA()">View all
                                    saved PAs</button>
                            </div>

                        </span>
                    </div>
                    <div class="col-md-5 col-xs-6" style="float:right" *ngIf="userrole==20">
                        <prescriberfilter [reqfrom]="reqFrom" (presbrId)="onNpiSelected($event)">

                        </prescriberfilter>

                    </div>
                    <section>
                        <savedpahistory [newSavedData]="savedData" [maxResults]="maxHistoryCount"></savedpahistory>
                    </section>
                    <div class="col-xs-12" style="padding-right: 0px;margin-bottom: 30px;">
                        <button class="btn btn-secondary float-end fontbold m-3" (click)="gotoViewAllSavedPA()">View all
                            saved PAs
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
