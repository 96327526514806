import { ProfileService } from 'src/app/services/profile/profile.service';
import { USER_ROLE, KEY_CODE } from 'src/app/constants/appconstants';
import { Router } from '@angular/router';
import { Component, OnInit, HostListener, ViewChild, AfterViewInit, ElementRef ,NO_ERRORS_SCHEMA, Input} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PAGE_TYPE, ViewAllRouteState } from 'src/app/constants/routeconstants';
import { LoggerService } from 'src/app/services/logger/logger.service';
import {Title} from "@angular/platform-browser";
import { SavedPARequest } from 'src/app/modals/epa/priorauthrequest';
import { Prescriber } from 'src/app/modals/login/loginwebresponse';
import { PahistoryService } from 'src/app/services/pahistory/pahistory.service';
import { ViewallpaComponent} from '../viewallpa/viewallpa.component';
import { PAResponseHistory,PAHistorywebresponse } from 'src/app/modals/pahistory/pahistorywebresponse';
import { viewAllNotificationsRequest } from 'src/app/modals/viewallnotificationscalls/viewAllNotificationsRequest';
import { NotificationsService } from 'src/app/services/viewAllNotificationsServices/notifications.service';
import {BOOLEAN_FLAG} from 'src/app/constants/appconstants'
import { PrescriberfilterComponent } from '../../prescriberfilter/prescriberfilter.component';
import { PAHistorywebrequest } from 'src/app/modals/pahistory/pahistorywebrequest';
import { DatetoDisplayString } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { TableRowData } from 'src/app/modals/datatable/datatable';
import { SavedpahistoryComponent } from '../../savedpahistory/savedpahistory.component';
import { SubmittedpahistoryComponent } from '../../submittedpahistory/submittedpahistory.component';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  userrole!:USER_ROLE;
  currenttab:number=1;
  loading:boolean=false;
  userRole!: string;
  userID!: string;
  useruuid!: string;
  newNotificationsCount:number=0;
  @ViewChild(SubmittedpahistoryComponent,{static:false})
  private submittedHistoryRef!:SubmittedpahistoryComponent;
  @ViewChild(SavedpahistoryComponent, {static:false})
  private savedHistoryRef!:SavedpahistoryComponent;
 // role!: USER_ROLE
  viewallprescribers: string = 'View all prescribers';
  sourceData!:PAResponseHistory[];
  savedData!:PAResponseHistory[];
  maxHistoryCount:number=10;
  maxResults?: number;
  providernpi!:String;
  changedPrescriberId:any=this.viewallprescribers;
  changedprovidernpi:any = this.providernpi
  state!:ViewAllRouteState;
  viewall!:ViewallpaComponent;
  private _activeTab : number=1;
  recentSearchSession:any = undefined;
  recentSearchDrugSession:any = undefined;
  reqFrom:String='PA';

  constructor(private profileService:ProfileService,
    private pahisoryservice:PahistoryService,
    private router:Router,
    private notificaionsService:NotificationsService,
    private loggerService: LoggerService,private titleService:Title,
    private modalService: NgbModal, private pahistoryservice: PahistoryService,
    private dateToDisplayStringConversion: DatetoDisplayString,
  )
  
  
  {
    if(this.profileService.loginuser!=undefined){
      this.userrole = this.profileService.USER_ROLE;
    this.userrole = this.profileService.USER_ROLE;
    this.userID = "" + this.profileService.loginuser?.prescriber?.presbrId;
    this.useruuid = "" + this.profileService.loginuser?.prescriber?.uuid;
    }
  }
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return year + '-' + month + '-' + day;
  
  }
  
  getDate30DaysBack(): Date {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  }
  ngOnInit(): void {
    debugger;
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    this.reload();
     //for member search and drug search recent search session
     if(sessionStorage.getItem("recentMemberSearches")!=undefined)
      this.recentSearchSession= JSON.parse(""+sessionStorage.getItem("recentMemberSearches"));
      if(sessionStorage.getItem("recentDrugSearches")!=undefined)
      this.recentSearchDrugSession= JSON.parse(""+sessionStorage.getItem("recentDrugSearches"));
      sessionStorage.clear();
      if(this.recentSearchSession!=undefined)
      sessionStorage.setItem("recentMemberSearches",JSON.stringify(this.recentSearchSession));
      console.log(this.recentSearchSession);
      if(this.recentSearchDrugSession!=undefined)
        sessionStorage.setItem("recentDrugSearches",JSON.stringify(this.recentSearchDrugSession));
        console.log(this.recentSearchDrugSession);
  }
  reload(){
    var apiParams = new PAHistorywebrequest();
    apiParams.toDate = this.formatDate(this.getTodate());
    apiParams.fromDate = this.formatDate(this.getDate30DaysBack());
    apiParams.maxResults = 10;
    apiParams.providerId = this.useruuid;
    if (this.userrole == 20) {
      apiParams.providerNPI = this.providernpi;
      apiParams.adminPresbrID = this.userID
    }
    this.getNotifications(apiParams);
  }
  onNpiSelected($event: String) {
    debugger;
    //this.providernpi = $event;
    console.log(`Provider NPI updated to: ${this.providernpi}`);
    var apiParams = new PAHistorywebrequest();
    apiParams.toDate = this.formatDate(this.getTodate());
    apiParams.fromDate = this.formatDate(this.getDate30DaysBack());
    apiParams.maxResults = 10;
    apiParams.providerId = this.useruuid;
    if ($event != this.viewallprescribers) {
      // apiParams.adminPresbrID =this.profileService.loginuser.prescriber?.presbrId;
      apiParams.adminPresbrID = '';
      this.changedprovidernpi=$event;
      apiParams.providerNPI = this.changedprovidernpi;

    }
    else if ($event == this.viewallprescribers) {      
      apiParams.adminPresbrID =this.profileService.loginuser.prescriber?.presbrId;
      this.changedprovidernpi=this.viewallprescribers;

    }
    this.getNotifications(apiParams);
  }

  getTodate(): Date {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  }
  /*changePrescriber($event: String) {
    var apiParams = new PAHistorywebrequest();
    apiParams.toDate = this.formatDate(new Date());
    apiParams.fromDate = this.formatDate(this.getDate180DaysBack());
    apiParams.maxResults = 10;
    apiParams.providerId = this.useruuid;
    if ($event != this.viewallprescribers) {
      apiParams.adminPresbrID =this.profileService.loginuser.prescriber?.presbrId;
      apiParams.providerNPI = this.changedprovidernpi;
      this.changedPrescriberId=$event;
    }
    else if ($event == this.viewallprescribers) {
      apiParams.adminPresbrID = '';
      this.changedPrescriberId=this.viewallprescribers;

    }
    this.getNotifications(apiParams);
  }*/
  getNotifications(params:any) {
    //this.state = history.state;
    
  if(this.currenttab==2){
    this.pahisoryservice.getSubmittedPAHistory(params).then((response:PAHistorywebresponse)=>{
     this.loading=true;
    debugger;
      this.sourceData = response.paResponseHistory;
      
      //this.notificationCount=response.paResponseHistory.length;
   

  }
  ,(error:any)=>{
    this.submittedHistoryRef.submittedPaHistory=[];
    //this.filterHistory();
  }).catch((reason:any)=>{
    this.submittedHistoryRef.submittedPaHistory=[];
   // this.filterHistory();
  });
}
else if(this.currenttab==3){
this.pahisoryservice.getSavedPAHistory(params).then((response: PAHistorywebresponse) => {
  this.loading=true;  
  debugger;
      this.savedData = response.savedPaAutherizations;
   // this.filterHistory();      
  }
  ,(error:any)=>{
    this.savedHistoryRef.savedPaHistory=[];
   // this.filterHistory();
  }).catch((reason:any)=>{
    this.savedHistoryRef.savedPaHistory=[];
    
    //this.filterHistory();
  });
}

  console.log(this.userrole)
}
  changeTimeformat(dateSubmitted: string): string {
    throw new Error('Method not implemented.');
  }



  showHistory(tabIndentifier:number):void{

  }
  getnewNotificationsCount($event:number){
    debugger;
    this.newNotificationsCount=$event;

  }
  gotoViewAllSubmittedPA() {
    this.loggerService.userAction("PA_History")
    const savedState: ViewAllRouteState = { pageType: PAGE_TYPE.SUBMITTEDPAS};
    this.router.navigateByUrl("/ViewAllSubmittedPAs", { state: savedState });
    this.titleService.setTitle("View All Submitted PA History");
  }
  gotoViewAllSavedPA() {
    this.loggerService.userAction("View All Saved PA clicked")
    const savedState: ViewAllRouteState = { pageType: PAGE_TYPE.SAVEDPAS };
    this.router.navigateByUrl("/ViewAllSavedPAs", { state: savedState });
    this.titleService.setTitle("View All Saved PAs");
  }
  goto(routerUrl: string) {
    this.router.navigateByUrl("/" + routerUrl);
  }
  public get activeTab() : number {
    return this._activeTab;
  }
  public set activeTab(v : number) {
    this._activeTab = v;
    switch(v){
      case 1:
        this.loggerService.log("Home page - notifications tab selected.");
        break;
      case 2:
        this.loggerService.log("Home page - Submitted prior authorization history tab selected.");
        break;
      case 3:
        this.loggerService.log("Home page - Saved prior authorization history tab selected.");
        break;
    }
  }

}
