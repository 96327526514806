import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { getRecentPrescriberDetailsRequest } from 'src/app/modals/viewallnotificationscalls/getRecentPrescriberDetailsRequest';
import { getRecentPrescriberDetailsResponse } from 'src/app/modals/viewallnotificationscalls/getRecentPrescriberDetailsResponse';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { NotificationsService } from 'src/app/services/viewAllNotificationsServices/notifications.service';

@Component({
  selector: 'prescriberfilter',
  templateUrl: './prescriberfilter.component.html',
  styleUrls: ['./prescriberfilter.component.css']
})
export class PrescriberfilterComponent implements OnInit {


  constructor(private profileService: ProfileService, private router: Router, private notificaionsService: NotificationsService) {
    if (this.profileService.loginuser != undefined) {
      this.adminId = "" + this.profileService.loginuser?.prescriber?.presbrId;
      this.uuid = "" + this.profileService.loginuser?.prescriber?.uuid;
    }

  }

adminId!: any;

uuid!: any;
  @Input() reqfrom!:String;
  @Input() presLabel:boolean =false;
  @Output() presbrId = new EventEmitter<String>();

  ngOnInit(): void {
    var apiDetails = new getRecentPrescriberDetailsRequest();
    apiDetails.adminPresbrID = this.adminId;
    apiDetails.uuid = this.uuid;
    this.getAssociatedUserDetials(apiDetails);


  }
  //prescriber dropdown code
  associatedUsers!: any;
  names: string[] = [];
  viewallprescribers: string = 'View all prescribers';
  searchQuery: string = this.viewallprescribers;
  selectedName: string = this.viewallprescribers;
  filteredNames: string[] = [];
  dropdownVisible: boolean = false;

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return year + '-' + month + '-' + day;

  }

  getDate180DaysBack(): Date {
    const date = new Date();
    date.setDate(date.getDate() - 180);
    return date;
  }

  changeTimeformat(time: string) {
    var year = time.slice(0, 4);
    var month = time.slice(5, 7);
    var day = time.slice(8, 10);
    return month + '/' + day + '/' + year;
  }

  searchNames(): void {
    if (this.searchQuery) {
      this.filteredNames = this.names.filter(name =>
        name?.toLowerCase().includes(this.searchQuery?.toLowerCase())
      );
    } else {
      this.filteredNames = [...this.names];
    }
  }

  showDropdown(): void {
    this.dropdownVisible = true;
    this.searchQuery = ''; // Clear the input field
    this.searchNames();
  }

  hideDropdown(): void {
    setTimeout(() => {
      this.dropdownVisible = false;
      if (!this.filteredNames.includes(this.searchQuery) && this.searchQuery !== this.viewallprescribers) {
        this.searchQuery = this.selectedName; // Revert to selected name if input is not in filtered names
      }
    }, 200); // Delay to allow item click
  }

  highlightMatch(name: string): string {
    const query = this.searchQuery?.toLowerCase();
    const index = name?.toLowerCase().indexOf(query);
    if (index >= 0) {
      return name.slice(0, index) + '<b>' + name.slice(index, index + query.length) + '</b>' + name.slice(index + query.length);
    }
    return name;
  }

  selectName(name: string): void {
    debugger
    this.selectedName = name;
    this.searchQuery = name;
    this.filteredNames = [];
    this.dropdownVisible = false;
    var npi = name.slice(-10);
    for (let i = 0; i < this.associatedUsers.length; i++) {
      if (this.associatedUsers[i].npi == npi) {
        if(this.reqfrom=='notifications'){
          this.presbrId.emit(this.associatedUsers[i].presbrId);
        }
        else{
          this.presbrId.emit(this.associatedUsers[i].npi);
        }
      }
    }

  }
  selectAllName() {
    debugger
    this.selectedName = this.viewallprescribers;
    this.searchQuery = this.viewallprescribers;
    this.filteredNames = [];
    this.dropdownVisible = false;

    this.presbrId.emit(this.viewallprescribers);

  }
  @ViewChild('inputField') inputField!: ElementRef<HTMLInputElement>;
  selectText() {
    this.inputField.nativeElement.select();
  }

  getAssociatedUserDetials(params: getRecentPrescriberDetailsRequest) {
    this.notificaionsService.postGetPrescriberDetails(params).then((data: getRecentPrescriberDetailsResponse) => {
      if (data.status.statusCode == 'Success') {
        this.associatedUsers = data.prescriberInfoAll;
        for (let i = 0; i < data.prescriberInfoAll.length; i++) {
          this.names[i] = data.prescriberInfoAll[i].lastName + ' ' + data.prescriberInfoAll[i].firstName + ' - ' + data.prescriberInfoAll[i].npi;
        }
      }
    });
  }

}
