<div class="container showallNotifications ng-scope">
    <div class="row notificationHeader">
        <div class="col-sm-12 col-md-12 col-lg-10  nopadding"
            style="padding-bottom:30px !important;margin-left: 8.33333333%;">
            <span class="back_to_home" style="cursor: pointer;" (click)="onBackClick()">
                <i class="fa fa-angle-left" aria-hidden="true"></i>&nbsp; Back to View All Notifications
            </span>
        </div>
        <div style="margin-left: 8.33333333%;padding: 0 !important;width: 83.33333333%;display: flex;">
            <div class="col-sm-7 col-md-7 col-lg-7 text-left nopadding">
                <h1 class="heading_1 ng-binding" style="margin-bottom: 10px;">Recently Deleted Notifications</h1>
                <span class="paragraph ng-binding">View recently deleted notifications, they will be permanently removed
                    after 7 days. Narrow your results by using the filter or search options.</span>
            </div>
            <div class="col-sm-5 col-md-5 col-lg-5 nopadding" *ngIf="role==20">
                <div class="pull-right prescribersdropdown" style="width: 100%;
                padding-top: 15px;">
                    <prescriberfilter [reqfrom]="reqFrom" (presbrId)="changePrescriber($event)">

                    </prescriberfilter>

                </div>
            </div>
        </div>
    </div>
    <div class="row notificationBody home_dashboard">
        <div class="col-sm-12 col-md-12 col-lg-10 nopadding column"
            style="border-radius: 2px 2px 0 0;margin: 0 8.33333333%;">
            <div class="content-card-holder">
                <div class="content-card no-shadow">
                    <div class="header">
                        <div class="container-fuild nopadding notificationsfilters">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                                    style="margin-top:10px;margin-bottom:10px;padding-right:0px;padding-left: 15px;">
                                    <label for="statusRange" class="inputFieldLabel ng-binding">Status:&nbsp;</label>
                                    <select id="statusRange" class="drop-list" [(ngModel)]="userSelectedStatus">
                                        <option label="All Statuses" value="ViewAll" selected="selected">All Statuses
                                        </option>
                                        <option label="Approved" value="Approved">Approved</option>
                                        <option label="Denied" value="Denied">Denied</option>
                                        <option label="Cancelled" value="Cancelled">Cancelled</option>
                                        <option label="Saved PA Expiring" value="SavedPA">Saved PA Expiring</option>
                                    </select>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12"
                                    style="margin-top:10px;margin-bottom:10px;padding-right: 15px;padding-left: 15px;">
                                    <input type="text"
                                        style="margin: 0;font-size: 16px;font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;height: 44px;border: 2px solid #626f7d;background-color: #fff;color: #050709;padding: 10px;width: 84%;"
                                        [(ngModel)]="searchText">
                                    <button type="button" (click)="onFilterSearch()"
                                        style="padding-left: 20px;padding-right: 20px;font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;height: 44px;font-size: 16px;"
                                        class="btn btn-primary btn_primary pull-right push-right-sm ">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="body tab-pane" style="padding: 10px;padding-bottom: 0px;">
                        <div style="font-size: 16px;margin-left: 35px;margin-top: 25px;" *ngIf="errorRetreivingdata; else nexterrormsg"><br>We were unable to retrieve this information. Refresh the page
                            to try again. <span class="refresh">Refresh page</span>
                        </div>
                        <ng-template #nexterrormsg>
                        <div style="font-size: 16px;margin-left: 35px;margin-top: 25px;" *ngIf="(unfilterednotifications!=undefined && unfilterednotifications.length ==0) && ( allNotifications!=undefined && allNotifications.length==0)">
                            <span class="paragraph">There are no notifications for this prescriber.</span>
                        </div>
                    </ng-template>
                        <div style="font-size: 16px;margin-left: 35px;margin-top: 25px;"  *ngIf="(allNotifications!=undefined && allNotifications.length==0) && (unfilterednotifications!=undefined && unfilterednotifications.length != 0)">
                            There are no matching notifications.
                        </div>
                        <div class="submitted_PA_results">

                            <div class="submitted_PA_progress text-center" style="margin-top: 30px;"
                                *ngIf="allNotifications==undefined">
                                <div>
                                    <i class="fa fa-circle-o-notch fa-spin"
                                        style=" color: #ef6c00;font-size: 30px;display: inline-block;vertical-align: middle;"></i>
                                    <span>&nbsp;Loading...</span>
                                </div>
                            </div>
                            <div class="info-report-border-separate">
                                <viewalldatatable [tabledata]="allNotifications" [action]="dltNtfyType" (viewPa)="onViewPAClick($event)">
            
                                </viewalldatatable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>