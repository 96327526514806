<div class="container-fluid viewAll savedHistory" *ngIf="state.pageType==0">
    <div class="row notificationHeader">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10 row">
            <!--<div style="margin-left: 8.33333333%;padding: 0 !important;width:95%;display: flex;"> -->
            <div class="row no-padding">
                <div class="col-sm-7 col-md-7 col-lg-7 text-left">
                    <h1 class="heading_1 ng-binding">View All Saved PAs</h1>
                    <span class="paragraph ng-binding" style="display: block;">
                        Find all Saved PAs in here.Narrow your results by using the filter or<br> search options.
                    </span>
                </div>
                <div class="col-sm-5 col-md-5 col-lg-5" *ngIf="role==20">
                    <div class="pull-right prescribersdropdown" style="float:right;
                ">
                         <prescriberfilter [reqfrom]="reqFrom" (presbrId)="onNpiSelected($event)">

                         </prescriberfilter>


                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10">
        <h1 name="title" class="fs-3">View all Saved PAs</h1>
        <div class="col-md-5 col-xs-6" style="float:right;" *ngIf="role==20" >
            <prescriberfilter (presbrId)="changePrescriber($event)">

            </prescriberfilter>
        </div>
    </div>
    <div class="row">
        <div class="offset-md-2 col-md-6 offset-xs-1 col-xs-6 mt-3">
            <p name="description" class="large">Find all Saved PAs in here. 
                Narrow your results by using the filter or<br> search options.</p>
        </div>
       
    </div>
    </div>-->

        <div class="row">
            <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10">
                <div class="content-card bg-white">
                    <div class="card-header">

                        <div class="control">
                            <label>View:</label>&nbsp;
                            <select class="rounded-0 inp form-select dropdown responsive"
                                [(ngModel)]="savedHistory.selectedStatus">
                                <option *ngFor="let option of savedHistory.savedStatus | keyvalue"
                                    [innerHtml]="option.value" [value]="option.key"></option>
                            </select>
                        </div>

                        <div class="control">
                            <input type="text" class="form-control rounded-0 inp"
                                [(ngModel)]="savedHistory.searchText" />
                        </div>
                        <div class="control">
                            <button class="btn btn-primary fontbold" (click)="filterHistory()">Search</button>
                        </div>

                    </div>
                    <!--<div *ngIf="numberofnotifications==0">
                    <span style="display:block;padding: 20px 20px 0px;" class="paragraph">There are no saved PAS for
                        this prescriber.</span>
                </div>
                <div class="submitted_PA_progress text-center" style="margin-top: 30px;"
                    *ngIf="numberofnotifications==undefined">
                    <div>
                        <i class="fa fa-circle-o-notch fa-spin"
                            style="color: #ef6c00;font-size: 30px;display: inline-block;vertical-align: middle;"></i>
                        <span style="padding-left: 5px;position: relative;top: 2px">Loading...</span>
                    </div>
                </div>-->
                    <div class="card-content">
                        <savedpahistory [memberId]="this.memberId" [pageType]="this.memberSpecific"
                            [searchText]="savedHistory.searchText" [filterByStatus]="savedHistory.selectedStatus">
                        </savedpahistory>
                    </div>
                    <div class="submitted_PA_results">

                        <div class="submitted_PA_progress text-center" style="margin-top: 30px;" *ngIf="loading">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10 text-start backToHome">
                <i class="fa fa-angle-left"></i>
                &nbsp;
                <a class="btn btn-link backToHome" (click)="goto('home')" (keydown.enter)="goto('home')" tabindex="0"
                    aria-hidden="true">
                    BACK to PCMS homepage
                </a>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid viewAll submittedHistory" *ngIf="state.pageType==1">
    <div class="row notificationHeader">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10">
            <div style="padding: 0 !important;width: 100%;display: flex;">
                <div class="col-sm-7 col-md-7 col-lg-7 text-left nopadding">
                    <h1 class="heading_1 ng-binding">View All Submitted PAs</h1>
                    <span class="paragraph ng-binding" style="display: block;">
                        Find all submitted prior authorizations here. Narrow your results by using the filter or search
                        options.
                    </span>
                </div>
                <div class="col-sm-5 col-md-5 col-lg-5 nopadding" *ngIf="role==20">
                    <div class="pull-right prescribersdropdown" style="width: 100%;
                padding-top: 20px;">
                         <prescriberfilter [reqfrom]="reqFrom" (presbrId)="onNpiSelected($event)">

                         </prescriberfilter>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--<div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10">
            <h1 name="title">View all submitted PAs</h1>
            <div class="col-md-5 col-xs-6" style="float:right;" *ngIf="role==20" >
                <prescriberfilter (presbrId)="changePrescriber($event)">
    
                </prescriberfilter>
    
        </div>
    </div>
    </div>
    <div class="row">
        <div class="offset-md-2 col-md-6 offset-xs-1 col-xs-8">
            <p name="description" class="large">Find all submitted prior authorizations here. Narrow your results
                 by using<br> the filter or search options.</p>
        </div>
    </div>-->

    <div class="row">
        <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10">
            <div class="content-card bg-white">
                <div class="card-header">
                    <div class="control">
                        <div class="row">
                            <label class="p-0 mb-1" for="dateRange" id="dateRange">Date range</label>
                            <select class="rounded-0 inp form-select dropdown responsive"
                                [(ngModel)]="submittedHistory.selectedDate">
                                <option *ngFor="let option of submittedHistory.dateRange | keyvalue"
                                    [innerHtml]="option.value" [value]="option.key"></option>
                            </select>
                        </div>
                    </div>
                    <div class="control">
                        <div class="row">
                            <label class="p-0 mb-1">Status</label>
                            <select class="rounded-0 inp form-select dropdown responsive"
                                [(ngModel)]="submittedHistory.selectedStatus">
                                <option *ngFor="let option of submittedHistory.statuses | keyvalue"
                                    [innerHtml]="option.value" [value]="option.key"></option>
                            </select>
                        </div>
                    </div>
                    <div class="control">
                        <input type="text" class="form-control rounded-0 inp"
                            [(ngModel)]="submittedHistory.searchText" />
                    </div>
                    <div class="control">
                        <button class="btn btn-primary fontbold" (click)="filterHistory()">Search</button>
                    </div>
                </div>
                <div class="card-footer" *ngIf="submittedHistory.selectedDate==5">
                    <!--Below ngIf on fromDate component is required because, fromDate component depends on toDate component to be loaded first.
                        Hence, We use this flag to delay fromDate component loading until toDate component loads.-->
                    <div class="control" *ngIf="submittedHistory.blnShowFromDate">
                        <label for="dateSearch" id="dateSearch">Search by date:</label>&nbsp;
                        <datefield #FromDate [validationMode]="submittedHistory.fromDateValidationMode"
                            [content]="submittedHistory.fromDateValue"
                            (onUpdate)="submittedHistory.onFromDateUpdate($event)"
                            [emptyValid]="!submittedHistory.blnCustomDateFormSubmitted"
                            [toDateComponent]="ToDateComponent" [placeHolder]="submittedHistory.dateFormatPlaceHolder">
                        </datefield>
                    </div>
                    <div class="control">
                        &nbsp;<label for="todateSearch" id="todateSearch">To</label>&nbsp;
                        <datefield #ToDate [validationMode]="submittedHistory.toDateValidationMode"
                            [content]="submittedHistory.toDateValue"
                            (onUpdate)="submittedHistory.onToDateUpdate($event)"
                            [emptyValid]="!submittedHistory.blnCustomDateFormSubmitted"
                            [placeHolder]="submittedHistory.dateFormatPlaceHolder"></datefield>
                    </div>
                    <div class="text-end">
                        <button class="btn btn-secondary" (click)="filterHistory()">Update</button>
                    </div>
                </div>
                <div class="card-content">
                    <submittedpahistory *ngIf="this.memberSpecific==undefined" [memberId]="this.memberId"
                        [fromDate]="submittedHistory.fromDate" [toDate]="submittedHistory.toDate"
                        [searchText]="submittedHistory.searchText" [filterByStatus]="submittedHistory.selectedStatus">
                    </submittedpahistory>
                    <membersubmittedpahistory *ngIf="this.memberSpecific!=undefined" [memberId]="this.memberId"
                        [fromDate]="submittedHistory.fromDate" [toDate]="submittedHistory.toDate"
                        [searchText]="submittedHistory.searchText" [filterByStatus]="submittedHistory.selectedStatus">
                    </membersubmittedpahistory>
                </div>
                <div class="submitted_PA_results">

                    <div class="submitted_PA_progress text-center" style="margin-top: 30px;" *ngIf="loading">
                        <!--<div>
                            <i class="fa fa-circle-o-notch fa-spin"
                                style=" color: #ef6c00;font-size: 30px;display: inline-block;vertical-align: middle;"></i>
                            <span>&nbsp;Loading...</span>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="offset-md-2 col-md-8 offset-xs-1 col-xs-10 text-start backToHome">
                <i class="fa fa-angle-left"></i>
                &nbsp;
                <a class="btn btn-link backToHome" (click)="goto('home')" (keydown.enter)="goto('home')" tabindex="0"
                    aria-hidden="true">
                    BACK to PCMS homepage
                </a>
            </div>
        </div>
    </div>
</div>