<div>
    <div class="row" style="margin: 0px;">
        <div class="col-md-7 col-xs-6" style="padding-left:35px;padding-right:0px">
            <h2 class="ng-binding">Notifications</h2>
            <h3 style="display:inline;font-size:15px;" class="a11y_h6 paragraph">View your 10 most recent notifications.
                You have
                <span class="notification_count">{{numberofnewnotifications}} new</span>
                notifications,<br>which are indicated by the icon in the corner
            </h3>
            <span style="display:inline-block">
                <span style="display:inline-block;font-size:15px" class="arrow-up notification_new"></span>.
                <span *ngIf="role==20" (click)="onViewAllNotificationsClick()" class="member_search_button">View all notifications</span>
            </span>
        </div>
        <div class="col-md-5 col-xs-6" style="padding-right:35px;" *ngIf="role==10 || role==13">
            <span class="pull-right" style="display:inline-block;width:100%;max-width:350px;margin-top: 5px;">
                <div class="ng-scope">
                    <button class="btn secondary pull-right ng-scope" style="height:44px"
                        (click)="onViewAllNotificationsClick()">View all
                        notifications</button>
                </div>

            </span>
        </div>
        <div class="col-md-5 col-xs-6" style="padding-right:35px;" *ngIf="role==20">
            <prescriberfilter [reqfrom]="reqFrom"  (presbrId)="changePrescriber($event)">

            </prescriberfilter>
            
        </div>
    </div>

    <div class="row notifications_noresults">
        <div class="col-xs-12">
            <span style="display:block;font-size:15px;padding: 20px 20px 0px;" class="paragraph">Some members may be
                eligible for prior authorization renewals. Please <span class="member_search_button" (click)="goto('/searchMedicationClaims/MemberSearch')">search for a
                    member</span> to view their prior authorizations.</span>
            <!-- <div ng-show="timeoutmodal &amp;&amp; !blnhide" style="margin-left:20px" class="ng-binding ng-hide"><br>We were unable to retrieve this information. Refresh the page to try again. <a href="javascript:void(0)" ng-click="refreshHandler()" class="ng-binding">Refresh page</a></div> -->
            <div class="submitted_PA_results fadeout-widget">
                <div *ngIf="numberofnotifications==0">
                    <span style="display:block;padding: 20px 20px 0px;" class="paragraph">There are no notifications for
                        this prescriber.</span>
                </div>
                <div class="submitted_PA_progress text-center" style="margin-top: 30px;"
                    *ngIf="numberofnotifications==undefined">
                    <div>
                        <i class="fa fa-circle-o-notch fa-spin"
                            style="color: #ef6c00;font-size: 30px;display: inline-block;vertical-align: middle;"></i>
                        <span style="padding-left: 5px;position: relative;top: 2px">Loading...</span>
                    </div>
                </div>
                <div class="info-report-border-separate" ng-show="items.length>0 && !hide">
                    <viewalldatatable [tabledata]="allNotifications" [action]="dltNtfyType" (updateondelete)="onDelete($event)" (viewPa)="onViewPAClick($event)" >

                    </viewalldatatable>                    
                </div>
            </div>


        </div>
    </div>

    <div class="row" style="margin: 0px;">
        <div class="col-xs-12" style="padding-right: 35px;margin-bottom: 30px;">
            <button class="btn secondary pull-right" (click)="onViewAllNotificationsClick()">View all
                notifications</button>
        </div>
    </div>
</div>
<ng-template #errorsavedpa let-modal>
    <div class="container-fluid content-card" aria-modal="true" aria-labelledby="errorsavedpa" role="dialog"
        style="padding: 36px 30px 10px;">
        <div class="row">
            <div class="col paragraph large text-center">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <br />
                An error occured. Please try again.
            </div>
        </div>
        <div class="row">
            <div class="col text-center card-footer" style="background-color: #fff;">
                <button type="button" class="btn btn-secondary" style="font-family: 'Frutiger-Bold';"
                    (click)="modal.dismiss()">Close</button>
                <!-- &nbsp;&nbsp;
                <button type="button" style="font-family: 'Frutiger-Bold';" class="btn btn-primary"
                    (click)="rerunsavedPA()">Try again</button> -->
            </div>
        </div>
    </div>
</ng-template>
<!-- <viewalldatatable ></viewalldatatable> -->