import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getUserSessionResponse } from 'src/app/modals/userSession/getUserSessionResponse';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'sessionerror',
  templateUrl: './sessionerror.component.html',
  styleUrl: './sessionerror.component.css'
})
export class SessionerrorComponent implements OnInit {

  @ViewChild("unableToReset", { static: true })
  unableToResetSession?: TemplateRef<HTMLElement>;

  showButton: boolean = false;
  constructor(private router: Router, private profileservice: ProfileService, private modalService: NgbModal) {

  }
  ngOnInit(): void {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    let showReset = sessionStorage.getItem('showSessionReset');
    if (showReset != null || showReset != undefined) {
      this.showButton = true;
    }
    else {
      this.showButton = false;
    }
  }

  resetSession() {
    debugger;
    let userdata = sessionStorage.getItem('loginuserdata');
    if(userdata!=null || userdata !=undefined ){
      let parsedUserData=JSON.parse(userdata);
      if(parsedUserData.prescribers[0].optumId!=undefined && parsedUserData.prescribers[0].optumId!=""){
        this.profileservice.resetUserSession(parsedUserData.prescribers[0].optumId).then((data: getUserSessionResponse) => {
          if (data.status.dbStatusCode == '1') {
            console.log('user session reset successful');
            this.loginToPreCheckMyScript();
          }
          else {
            console.log('user session reset failed');
            this.modalService.open(this.unableToResetSession, { size: 'md', backdrop: 'static' });
    
          }
        });
      }
      else{
        this.modalService.open(this.unableToResetSession, { size: 'md', backdrop: 'static' });
      }
    }
    else{
      this.modalService.open(this.unableToResetSession, { size: 'md', backdrop: 'static' });
    }
  }

  loginToPreCheckMyScript() {
    // window.open("https://precheckmyscript-stage.uhcprovider.com");
    // window.location.href = '/';
    window.location.href="https://localhost/rxlinkapp-msepa/";
    sessionStorage.removeItem("lastPageBeforeReload");
    // this.router.navigateByUrl('/');
  }

}
