import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BOOLEAN_FLAG, USER_ROLE, VIEWALL_DATERANGE, VIEWALL_DATERANGE_LEBELS, VIEWALL_NOTIFICATIONS_STATUS, VIEWALL_NOTIFICATIONS_STATUS_LABELS, VIEWALL_SUBMITTEDPA_STATUS, VIEWALL_SUBMITTEDPA_STATUS_LABELS } from 'src/app/constants/appconstants';
import { DateFieldEvent, DateFieldResultType, DateFieldValidationMode } from 'src/app/modals/datefield/datefield';
import { viewAllNotificationsRequest } from 'src/app/modals/viewallnotificationscalls/viewAllNotificationsRequest';
import { NotificationsService } from 'src/app/services/viewAllNotificationsServices/notifications.service';
import { DatefieldComponent } from '../datefield/datefield.component';
import { DatetoDisplayString, TimeStampToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ViewalldatatableComponent } from '../viewalldatatable/viewalldatatable.component';
import { NotificationResponse, viewAllNotificationsResponse } from 'src/app/modals/viewallnotificationscalls/viewAllNotificationsResponse';
import { StringToSentenceCase } from 'src/app/pipes/stringconvertions/stringconversions.pipe';
import { FilterobjectsByTextPipe } from 'src/app/pipes/filterobjects/filterobjects.pipe';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { SavedPAResponse } from 'src/app/modals/epa/priorauthresponse';

class NotificationHistory {
  searchText: string = "";

  savedStatus: Map<number, string> = VIEWALL_SUBMITTEDPA_STATUS_LABELS;
  blnStatusChanged: boolean = false;

  private _selectedStatus: number = VIEWALL_SUBMITTEDPA_STATUS.ALL;
  public get selectedStatus(): number {
    return this._selectedStatus;
  }
  public set selectedStatus(v: number) {
    this.blnStatusChanged = true;
    this._selectedStatus = v;
  }


}
class CustomDate {

  blnShowFromDate!: boolean;
  private _fromDateValue: string = "";
  public get fromDateValue(): string {
    return this._fromDateValue;
  }
  public set fromDateValue(v: string) {
    this._fromDateValue = v;
    if ((new Date(v)).toString() != "Invalid Date")
      this.fromDate = new Date(v);
  }
  fromDate: Date = new Date();//Different from fromDateValue. This fromDate is used to bind to history component where fromDateValue is used for validation on datefield.
  fromDateResult: DateFieldResultType = DateFieldResultType.EMPTY;
  fromDateValidationMode: DateFieldValidationMode = DateFieldValidationMode.ONBLUR;
  onFromDateUpdate(event: DateFieldEvent): void {
    this.fromDateValue = event.value;
    this.fromDateResult = event.valid;
  }

  blnCustomDateFormSubmitted: boolean = false;
  dateFormatPlaceHolder: string = "MM/DD/YYYY";


  private _toDateValue: string = "";
  public get toDateValue(): string {
    return this._toDateValue;
  }
  public set toDateValue(v: string) {
    this._toDateValue = v;
    if ((new Date(v)).toString() != "Invalid Date")
      this.toDate = new Date(v);
  }
  toDate: Date = new Date();//Different from toDateValue. This toDate is used to bind to history component where toDateValue is used for validation on datefield.
  toDateResult: DateFieldResultType = DateFieldResultType.EMPTY;
  toDateValidationMode: DateFieldValidationMode = DateFieldValidationMode.ONBLUR;
  onToDateUpdate(event: DateFieldEvent): void {
    this.toDateValue = event.value;
    this.toDateResult = event.valid;
  }

  dateRange: Map<number, string> = VIEWALL_DATERANGE_LEBELS;

  blnDateChanged!: boolean;
  private _selectedDate !: number;
  public get selectedDate(): number {
    return this._selectedDate;
  }
  public set selectedDate(v: number) {
    this.blnShowFromDate = false;
    this._selectedDate = v;
    this.blnDateChanged = true;
    if (parseInt(v.toString()) != VIEWALL_DATERANGE.CUSTOM_DATE) {
      //Today should represent either 11:59:59PM of today or 12AM of next day - 12AM of next day would be easy to code.
      let today: Date = new Date();
      today.setDate(today.getDate() + 1);
      this.toDate = today;

      this.fromDate = new Date();
      switch (parseInt(v.toString())) {
        case VIEWALL_DATERANGE.DAYS_THIRTY:
          this.fromDate.setDate(this.fromDate.getDate() - 30);
          break;
        case VIEWALL_DATERANGE.DAYS_SIXTY:
          this.fromDate.setDate(this.fromDate.getDate() - 60);
          break;
        case VIEWALL_DATERANGE.MONTHS_SIX:
          this.fromDate.setMonth(this.fromDate.getMonth() - 6);
          break;
        case VIEWALL_DATERANGE.YEARS_ONE:
          this.fromDate.setFullYear(this.fromDate.getFullYear() - 1);
          break;
      }

    } else {
      this.fromDateValue = "";
      this.toDateValue = "";
      this.fromDateResult = DateFieldResultType.EMPTY;
      this.toDateResult = DateFieldResultType.EMPTY;
      this.blnCustomDateFormSubmitted = false;
      setTimeout(() => {
        this.updateCustomSelectedDate();
        this.blnShowFromDate = true;
      }, 200);

    }
  }
  updateCustomSelectedDate(): void {
    if (this.fromDateResult == DateFieldResultType.SUCCESS && this.fromDateValue != undefined && this.fromDateValue.length > 0) {
      this.fromDate = new Date(this.fromDateValue);
    }
    if (this.toDateResult == DateFieldResultType.SUCCESS && this.toDateValue != undefined && this.toDateValue.length > 0) {
      this.toDate = new Date(this.toDateValue);
    }
  }
  statuses: Map<number, string> = VIEWALL_NOTIFICATIONS_STATUS_LABELS;
  selectedStatus: number = VIEWALL_NOTIFICATIONS_STATUS.ALL;
  searchText: string = "";

  reload(blnHardReload: boolean = true): void {
    if (blnHardReload) { }
  }

  constructor() {
    this.selectedDate = VIEWALL_DATERANGE.DAYS_THIRTY;
    this.blnDateChanged = false;
    this.blnShowFromDate = false;
  }
}

@Component({
  selector: 'notificationspage',
  templateUrl: './notificationspage.component.html',
  styleUrls: ['./notificationspage.component.css']
})

export class NotificationspageComponent implements OnInit {


  private _fromDate!: Date;

  public get fromDate(): Date {
    return this._fromDate;
  }
  @Input()
  public set fromDate(v: Date) {
    this._fromDate = v;
  }

  private _toDate!: Date;
  public get toDate(): Date {
    return this._toDate;
  }
  @Input()
  public set toDate(v: Date) {
    this._toDate = v;
  }

  filterByStatus!: VIEWALL_NOTIFICATIONS_STATUS;
  filterByTextArry!: string[];


  @Input()
  memberId!: string;

  blnLoading!: boolean;
  blnServerError!: boolean;
  // sourceData!: NotificationResponse[];
  filterByStatusArray!: NotificationResponse[];
  unabletofetchdata: boolean = false;

  userRole!: string;
  userID!: string;
  useruuid!: string;

  notificationCount!: any;
  userGivenFromDate!: string;
  userGivenToDate!: string;
  allNotifications!: any;
  staticNotifications: NotificationResponse[]=[];
  role!: USER_ROLE;
  viewallprescribers: string = 'View all prescribers';
  dltNtfyType: any = 'delete';
  notifcationFinalText: Array<any> = [];
  reqFrom='notifications';
  quesArry: any;
  questionArry: any;
  answArry: any;
  attachmentArry: any;
  changedPrescriberId:any=this.viewallprescribers;

  showDates:boolean=false;

  constructor(private stringToSentenceCase: StringToSentenceCase, private router: Router, private notificaionsService: NotificationsService, 
    private fb: FormBuilder, private dateToDisplayStringConversion: DatetoDisplayString, private profileService: ProfileService, 
    private epaServiceHelper: EpaprocessorService,private timestampTostandardDate: TimeStampToStandardDate) {
    if (this.profileService.loginuser != undefined) {
      this.role = this.profileService.USER_ROLE;
      this.role = this.profileService.USER_ROLE;
      this.userRole = "" + this.profileService.loginuser?.prescriber?.roleTypeId;
      this.userID = "" + this.profileService.loginuser?.prescriber?.presbrId;
      this.useruuid = "" + this.profileService.loginuser?.prescriber?.uuid;

    }
    this.submittedHistory = new CustomDate();
    this.notificationHistory = new NotificationHistory();
  }


  submittedHistory!: CustomDate;
  notificationHistory!: NotificationHistory;


  @ViewChild("FromDate", { static: false })
  private FromDateComponent!: DatefieldComponent;

  @ViewChild("ToDate", { static: false })
  ToDateComponent!: DatefieldComponent;

  @ViewChild(ViewalldatatableComponent, { static: false })
  private submittedHistoryRef!: ViewalldatatableComponent;



  ngOnInit(): void {

    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    sessionStorage.setItem('paBackToPage', 'viewall');

    var apiParams = new viewAllNotificationsRequest();
    apiParams.fromDate = this.dateToDisplayStringConversion.transform(this.submittedHistory.fromDate);
    apiParams.toDate = this.dateToDisplayStringConversion.transform(this.submittedHistory.toDate);
    apiParams.maxCount = '';
    apiParams.loginRoleId = this.userRole
    if (this.role == 20) {
      apiParams.adminPresbrId = this.userID
      apiParams.viewAllPresbrs = BOOLEAN_FLAG.YES;
      apiParams.presbrId = '';
    }
    else if (this.role == 10 || this.role == 13) {
      apiParams.presbrId = this.userID
      apiParams.viewAllPresbrs = BOOLEAN_FLAG.NO;
      apiParams.adminPresbrId = '';
    }

    this.getNotifications(apiParams);


  }
  getNotifications(params: any) {
    //reset error boolean flags
    this.unabletofetchdata = false;

    this.notificaionsService.postGetNotifications(params).then((data: viewAllNotificationsResponse) => {
      if (data.status.statusCode == "Success") {
        
        //convert capital to lower and change time for display purpose  
        for (let i = 0; i < data.notificationResponse.length; i++) {
          data.notificationResponse[i].firstName = data.notificationResponse[i].firstName?.toLowerCase();
          data.notificationResponse[i].lastName = data.notificationResponse[i].lastName?.toLowerCase();
          data.notificationResponse[i].convertedTimestamp = this.timestampTostandardDate.transform(data.notificationResponse[i].date.toString());
        }

        this.allNotifications = data.notificationResponse;
        this.staticNotifications = data.notificationResponse;
        this.notificationCount = data.notificationResponse.length;
        console.log(this.notificationCount);


        //to add search text to all notifications 
        for (let i = 0; i < this.staticNotifications.length; i++) {
          if (this.staticNotifications[i].paStatus == 'SavedPA') {
            this.staticNotifications[i].msg = "Saved PA Request Expiring A saved prior authorization request for " + this.staticNotifications[i].firstName + ' ' + this.staticNotifications[i].lastName + "'s for " + this.staticNotifications[i].drugName + " is expiring soon. Resume"
          }
          else if (this.staticNotifications[i].paStatus == 'Cancelled' || this.staticNotifications[i].paStatus == 'Denied' || this.staticNotifications[i].paStatus == 'Approved') {
            this.staticNotifications[i].msg = " PA Request " + this.staticNotifications[i].paStatus + " " + this.staticNotifications[i].firstName + ' ' + this.staticNotifications[i].lastName + "'s prior authorization request for " + this.staticNotifications[i].drugName + " has been " + this.staticNotifications[i].paStatus + ". View prior auth details"

          }
        }

        debugger;
        if (this.submittedHistory.selectedStatus == 5) {
          this.allNotifications = undefined;
          this.filterByStatusArray = this.staticNotifications.filter((item) => item.paStatus == "SavedPA");
          if (this.submittedHistory.searchText != '') {
            this.filterByStatusArray = this.filterByStatusArray.filter(item => item.msg?.toLowerCase().includes(this.submittedHistory.searchText?.toLowerCase()))
          }
          this.allNotifications = this.filterByStatusArray;

        }
        else if (this.submittedHistory.selectedStatus == 4) {
          this.allNotifications = undefined;
          this.filterByStatusArray = this.staticNotifications.filter((item) => item.paStatus == "Cancelled");
          if (this.submittedHistory.searchText != '') {
            this.filterByStatusArray = this.filterByStatusArray.filter(item => item.msg?.toLowerCase().includes(this.submittedHistory.searchText?.toLowerCase()))
          }
          this.allNotifications = this.filterByStatusArray;

        }
        else if (this.submittedHistory.selectedStatus == 3) {
          this.allNotifications = undefined;
          this.filterByStatusArray = this.staticNotifications.filter((item) => item.paStatus == "Denied");
          if (this.submittedHistory.searchText != '') {
            this.filterByStatusArray = this.filterByStatusArray.filter(item => item.msg?.toLowerCase().includes(this.submittedHistory.searchText?.toLowerCase()))
          }
          this.allNotifications = this.filterByStatusArray;

        }
        else if (this.submittedHistory.selectedStatus == 2) {
          this.allNotifications = undefined;
          this.filterByStatusArray = this.staticNotifications.filter((item) => item.paStatus == "Approved");
          if (this.submittedHistory.searchText != '') {
            this.filterByStatusArray = this.filterByStatusArray.filter(item => item.msg?.toLowerCase().includes(this.submittedHistory.searchText?.toLowerCase()))
          }
          this.allNotifications = this.filterByStatusArray;

        }
        else {
          this.filterByStatusArray = this.staticNotifications;
          if (this.submittedHistory.searchText != '') {
            this.filterByStatusArray = this.filterByStatusArray.filter(item => item.msg?.toLowerCase().includes(this.submittedHistory.searchText?.toLowerCase()))
          }
          this.allNotifications = this.filterByStatusArray;

        }


      }
      else {
        this.unabletofetchdata = true;
      }

      //   if(this.submittedHistory.searchText!='')
      //     this.allNotifications=this.allNotifications.filter((item:any)=>""+item.presbrId==this.profileService.loginuser?.prescriber?.presbrId).includes(this.submittedHistory.searchText);
      //   console.log(this.allNotifications);


      console.log(this.staticNotifications);
      console.log(this.staticNotifications.length);
      console.log(this.allNotifications);
      console.log(this.allNotifications.length);
      

    });
  }

  // textSearch(){
  //     // this.allNotifications=undefined;
  //      this.allNotifications=this.staticNotifications.filter(item =>item.msg?.toLowerCase().includes(this.submittedHistory.searchText?.toLowerCase()))               

  // }

  staticFilter = this.fb.group({
    dateRange: ['30'],
    status: ['status'],
    search: ['']

  });

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return year + '-' + month + '-' + day;

  }
  getTodate(): Date {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;

  }
  getDate30DaysBack(): Date {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  }
  changeTimeformat(time: string) {
    var year = time.slice(0, 4);
    var month = time.slice(5, 7);
    var day = time.slice(8, 10);
    return month + '/' + day + '/' + year;
  }

  onDeletedNotificationsClick() {
    this.router.navigateByUrl('/notifications/ViewDeleted');
  }

  onBackClick() {
    this.router.navigateByUrl('home');
  }

  removecustomdate(){
    if(this.submittedHistory.selectedDate!=5){
      this.showDates=false;
    }
  }

  filterHistory(): void {    
    this.submittedHistory.blnCustomDateFormSubmitted = false;
    if (parseInt(this.submittedHistory.selectedDate.toString()) == VIEWALL_DATERANGE.CUSTOM_DATE) {
      this.submittedHistory.blnCustomDateFormSubmitted = true;
      this.ToDateComponent.onFocusOut();
      this.FromDateComponent.onFocusOut();
      setTimeout(() => {
        if (this.submittedHistory.fromDateResult == DateFieldResultType.SUCCESS
          && this.submittedHistory.toDateResult == DateFieldResultType.SUCCESS) {
          this.reload(this.submittedHistory.blnDateChanged);
          this.submittedHistory.blnDateChanged = false;
        }

      }, 250);
    }
    else {
      if (this.notificationHistory.blnStatusChanged == false) {
        console.log(this.notificationHistory.selectedStatus);
        this.reload(this.notificationHistory.blnStatusChanged);
        this.notificationHistory.blnStatusChanged = false;
      }
      else {
        this.reload(this.notificationHistory.blnStatusChanged);
        this.notificationHistory.blnStatusChanged = true;
      }
    }
  }

  changePrescriber($event: String) {
    this.allNotifications = undefined;
    this.notificationCount = undefined;
    this.staticNotifications=[];


    var apiParams = new viewAllNotificationsRequest();
    apiParams.toDate = this.dateToDisplayStringConversion.transform(this.submittedHistory.toDate);
    apiParams.fromDate = this.dateToDisplayStringConversion.transform(this.submittedHistory.fromDate);
    apiParams.maxCount = '';
    apiParams.loginRoleId = this.userRole;
    apiParams.adminPresbrId = this.userID;
    if ($event != this.viewallprescribers) {
      apiParams.viewAllPresbrs = BOOLEAN_FLAG.NO;
      apiParams.presbrId = '' + $event;
      this.changedPrescriberId=$event;
    }
    else if ($event == this.viewallprescribers) {
      apiParams.viewAllPresbrs = BOOLEAN_FLAG.YES;
      apiParams.presbrId = '';
      this.changedPrescriberId=this.viewallprescribers;

    }
    this.getNotifications(apiParams);


  }
  onDelete($event: String) {
    if ($event == 'true')
      this.notificationCount = parseInt(this.notificationCount) - 1;
  }

  reload(blnHardReload: boolean = true): void {
    let webrequest: viewAllNotificationsRequest = new viewAllNotificationsRequest();
    webrequest.fromDate = this.dateToDisplayStringConversion.transform(this.submittedHistory.fromDate);
    this.userGivenFromDate = this.changeTimeformat(webrequest.fromDate);
    webrequest.toDate = this.dateToDisplayStringConversion.transform(this.submittedHistory.toDate);
    this.userGivenToDate = this.changeTimeformat(webrequest.toDate);


    webrequest.maxCount = '';
    webrequest.loginRoleId = "" + this.profileService.loginuser?.prescriber?.roleTypeId;
    if (this.role == 20) {
      webrequest.adminPresbrId = "" + this.profileService.loginuser?.prescriber?.presbrId;
      // webrequest.viewAllPresbrs = BOOLEAN_FLAG.YES;
      // webrequest.presbrId = '';
      if (this.changedPrescriberId != this.viewallprescribers) {
        webrequest.viewAllPresbrs = BOOLEAN_FLAG.NO;
        webrequest.presbrId = '' + this.changedPrescriberId;

      }
      else if (this.changedPrescriberId == this.viewallprescribers) {
        webrequest.viewAllPresbrs = BOOLEAN_FLAG.YES;
        webrequest.presbrId = '';

  
      }
    }
    else if (this.role == 10 || this.role == 13) {
      webrequest.presbrId = "" + this.profileService.loginuser?.prescriber?.presbrId;
      webrequest.viewAllPresbrs = BOOLEAN_FLAG.NO;
      webrequest.adminPresbrId = '';
    }
    this.showDates=true;

    if (blnHardReload) {

      this.blnLoading = true;
      this.blnServerError = false;
      this.staticNotifications = [];
      this.notificationCount = undefined;
      // this.resetPaginationDefaults();
      this.getNotifications(webrequest);
      // this.pahistory.getSubmittedPAHistory(webrequest).then((response:viewAllNotificationsResponse)=>{
      // this.blnLoading=false;
      // if(this.memberId!=undefined){
      //   this.sourceData = response.paResponseHistory.filter((item)=>item.memberId==this.memberId);
      // }else{
      //   this.sourceData = response.paResponseHistory;
      // }
      // this.loadHistory();      
      //   },(error:any)=>{
      //     this.submittedPaHistory=[];
      //     this.blnLoading=false;
      //     this.blnServerError=true;
      //     this.loadHistory();
      //   }).catch((reason:any)=>{
      //     this.submittedPaHistory=[];
      //     this.blnLoading=false;
      //     this.blnServerError=true;
      //     this.loadHistory();
      //   });
    } else {
      this.getNotifications(webrequest);
    }
  }

  goto($event: String) {
    // this.logger.userAction("Goto home clicked.");

    this.router.navigateByUrl("/PriorAuthorizationDetails");
  }

  onViewPAClick($event: NotificationResponse) {
    var paResponseHistory = new NotificationResponse;
    paResponseHistory = $event;
    if ($event.caseId != undefined) {

      this.epaServiceHelper.resumeCellClickPA("" + $event.caseId).then((response: SavedPAResponse) => {
        console.log(response);

        if (response.paInitiationResponseWeb.body == undefined) {

          this.quesArry = [];
        }
        else {
          this.quesArry = response.paInitiationResponseWeb.body.parequest.request.solicitedModel.questionSet.question;
        }
        //console.log("data",response);
        if (response.paResumeDetails != undefined) {

          this.attachmentArry = response.paResumeDetails.paAttachment;
          //console.log("attachments",this.attachmentArry);
        }
        else {
          this.attachmentArry = [];
        }

        sessionStorage.setItem("pAdetails", JSON.stringify(paResponseHistory));
        //console.log("quesArry",this.quesArry);
        sessionStorage.setItem("Array", JSON.stringify(this.quesArry));
        //sessionStorage.setItem("Modal",JSON.stringify(response.paInitiationResponseWeb.body.parequest.request.solicitedModel.questionSet));
        sessionStorage.setItem("Attachements", JSON.stringify(this.attachmentArry));
        this.router.navigateByUrl("/PriorAuthorizationDetails");

      });
    }
    else {
      sessionStorage.setItem("pAdetails", JSON.stringify(paResponseHistory));
      this.router.navigateByUrl("/PriorAuthorizationDetails");
    }
  }

  //end brace
}




