<div class="link_nav row">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-center nav-bar-title">
                <span href="#"><img id="u142_img" class="img u142_img" src="assets/images/u142.png">
                </span>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid" style="position: relative;top:165px;">
    <div class="row" style="text-align: center;">
        <div class="col-6" style="text-align: right; padding-right: 25px;">
            <img class="img" src="assets/images/errorpageimage.png"
                style="position: relative;top:0px;width: 320px;height: 320px;">
        </div>
        <div id="genericerror" class="col-6" style="text-align: left;padding-left: 15px;padding-right: 15px;">
            <h1 class="head_txt" id="header_txt">Cannot connect as session limit exceeded.</h1>
            <p class="paragraph_text">
                <span id="info_txt">You have already logged into another session or<br> the previous session closed abruptly.<br> Please close the other session and try again later.</span>
            </p>
            <button *ngIf="!showButton" id="tryagain" class="btn btn-primary primary try_again_btn" (click)="loginToPreCheckMyScript()">Reload</button>
            <button *ngIf="showButton" id="resetSession" class="btn btn-primary primary try_again_btn" (click)="resetSession()">Continue</button>
        </div>
        <div id="appdowntime" class="col-sm-6" style="text-align: left; display: none;">
            <h1 id="header_txt"></h1>
            <p class="paragraph" style="padding-top: 10px;padding-bottom: 20px;">
                <span id="info_txt">
                </span>
            </p>
        </div>
    </div>
</div>

<ng-template #unableToReset let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <p class="large" style="padding-top: 20px;">
                <span>We are unable to submit your request.</span>
                <br />
                <span>Please try again later.</span>
            </p>
            <br />
            <button class="btn btn-primary" (click)="modal.dismiss()">Ok</button>
        </div>
    </div>
</ng-template>
