import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ProfileService } from 'src/app/services/profile/profile.service';
import { LoggerService } from './services/logger/logger.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title: string = 'pcmsappdev';
  profile!: ProfileService;
  loader: boolean = true;
  constructor(private _profileService: ProfileService, protected router: Router, private route: ActivatedRoute, private loggerService: LoggerService) {
    this.profile = _profileService;
  }

  ngOnInit(): void {
    sessionStorage.setItem("isLoggedIn", String(true));

    // Log the initial URI
    const initialUrl = this.router.url;
    // console.log('Initial URL:', initialUrl);
    // console.log('Initial URL:', this.route);
    // console.log('Window URL:', window.location.href);
    const bypassRoutes = ['/verifyProvider', '/contactus', '/terms', '/privacy', '/error','/sessionError'];
    for (let i = 0; i < bypassRoutes.length; i++) {
      if (window.location.href.indexOf(bypassRoutes[i]) != -1) {
        sessionStorage.setItem("lastPageBeforeReload", bypassRoutes[i]);
        break;
      }
      else if (window.location.href.endsWith(".com") || window.location.href.endsWith(".com/")) {
        sessionStorage.removeItem("lastPageBeforeReload");
        break;
      }
    }

    // if(window.location.href.indexOf("/verifyProvider")!=-1){
    //   sessionStorage.setItem("lastPageBeforeReload","/verifyProvider");
    // }else if(window.location.href.endsWith(".com")|| window.location.href.endsWith(".com/")){
    //   sessionStorage.removeItem("lastPageBeforeReload");
    // }

    if (sessionStorage.getItem("lastPageBeforeReload") != null && sessionStorage.getItem("lastPageBeforeReload") != "") {
      //console.log("Rerouting:"+sessionStorage.getItem("lastPageBeforeReload"))
      if (bypassRoutes.includes("" + sessionStorage.getItem("lastPageBeforeReload"))) {
        this.loader = false;
        this.goto("" + sessionStorage.getItem("lastPageBeforeReload"));
      } else {
        this.goto("" + sessionStorage.getItem("lastPageBeforeReload"));
      }
    } else {
      sessionStorage.setItem("lastPageBeforeReload", this.router.url == "/" ? "" : this.router.url);
    }

    //user inactivity
    // this.listenForLogout();
  }
  // listenForLogout() {
  //   window.addEventListener('storage', (event) => {
  //     if (event.key === 'logout-event') {
  //       this._profileService.logoutUser().then((data: any) => {
  //         this.loggerService.logout();
  //         window.location.href = data;
  //         localStorage.clear();
  //       });
  //     }
  //   });
  //   localStorage.clear();
  //   sessionStorage.clear();
    
  // }

  goto(routeUrl: string) {
    this.router.navigateByUrl("/" + routeUrl);
  }
}
